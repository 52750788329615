import React, { Component } from 'react';
import { FormGroup, Label, Button, Input, Row, Col } from 'reactstrap';
import questionManager from '../questionManager';
import Question from '../../common/Question';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';
import CovidVaccinationPSDPrint from './CovidVaccinationPSDPrint';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';

const currentDate = new Date(new Date().toDateString());

const defaultSecondBookingWindowStart = 9; // weeks (this is the end of the window i.e. 8-9 weeks)
const secondBookingWindowStartList = [];
/*example
const secondBookingWindowStartList = [
    { cohort: 'CarehomeWorker', weeks: 8 },
    { cohort: 'SocialCareWorker', weeks: 8 }
];*/
const defaultThirdBookingWindowStart = 9; // weeks (this is the end of the window i.e. 8-9 weeks)
const thirdBookingWindowStartList = [];
const defaultBoosterBookingWindowStart = 27; // weeks (this is the end of the window i.e. 8-9 weeks)
const boosterBookingWindowStartList = [];

class ViewQuestionnaireSubmission extends Component {

    constructor(props) {
        super(props);

        const { submission, viewAsPsd, role } = this.props;

        const answers = [];

        submission.questionAnswers.map(qa => {
            let answer = '';
            if (qa.answers.length > 1) {
                const combinedAnswer = qa.answers.reduce((acc, cur, idx) => idx === 0 ? cur : acc + ", " + cur, '');
                answer = combinedAnswer;
            } else {
                answer = qa.answers[0];
            }

            answers.push({ key: qa.questionKey, answer: answer });
        });

        this.state = {
            questionnaire: 'COVID Vaccination',
            initialised: false,
            allowDateOverride: props.allowDateOverride || false,
            hasVipAccess: props.hasVipAccess || false,
            firstAppointmentDateOverride: false,
            secondAppointmentDateOverride: false,
            thirdAppointmentDateOverride: false,
            boost1AppointmentDateOverride: false,
            flu1AppointmentDateOverride: false,
            answers: answers,
            role: role,
            vip: '',
            eligible: '',
            healthcareworker: '',
            carehomeworker: '',
            carehomeorganisation: '',
            onbehalfof: '',
            onbehalfofrelationship: '',
            onbehalfofname: '',
            willingtopartake: '',

            infectionorfeverish: '',
            covidpositive4weeks: '',
            othervaccinepast14days: '',
            othervaccinedate: '',
            seriousreaction: '',
            seriousreactionconfirmed: '',
            allergic: '',
            allergicconfirmed: '',
            bleedingdisorder: '',
            bleedingdisorderconfirmed: '',
            warfarin: '',
            warfarinthresholdok: '',
            breastfeeding: '',
            pregnant: '',
            covidtrial: '',
            covidtrialapproved: '',
            confirmflujabrestrictions: '',
            vaccinetype: '',

            infectionorfeverish2: '',
            covidpositive4weeks2: '',
            othervaccinepast14days2: '',
            othervaccinedate2: '',
            seriousreaction2: '',
            seriousreactionconfirmed2: '',
            allergic2: '',
            allergicconfirmed2: '',
            bleedingdisorder2: '',
            bleedingdisorderconfirmed2: '',
            breastfeeding2: '',
            pregnant2: '',
            covidtrial2: '',
            covidtrialapproved2: '',
            confirmflujabrestrictions2: '',
            vaccinetype2: '',
            systemicallergicreactiontofirst: '',

            nhsnumber: '',
            forename: '',
            lastname: '',
            dateofbirth: '',
            gender: '',
            postcode: '',
            ethnicity: '',
            mobile: '',
            confirmedMobile: '',
            email: '',
            confirmedEmail: '',
            organisation: '',
            directorate: '',
            jobrole: '',
            departmentward: '',
            assignmentnumber: '',
            address: '',
            firstappointmentsite: '',
            firstappointmentlocation: '',
            firstappointmentdate: '',
            firstappointmenttimeslot: '',
            firstappointmenttype: '',
            secondappointmentsite: '',
            secondappointmentlocation: '',
            secondappointmentdate: '',
            secondappointmenttimeslot: '',
            secondappointmenttype: '',
            thirdappointmentsite: '',
            thirdappointmentlocation: '',
            thirdappointmentdate: '',
            thirdappointmenttimeslot: '',
            thirdappointmenttype: '',
            boost1appointmentsite: '',
            boost1appointmentlocation: '',
            boost1appointmentdate: '',
            boost1appointmenttimeslot: '',
            boost1appointmenttype: '',
            flu1appointmentsite: '',
            flu1appointmentlocation: '',
            flu1appointmentdate: '',
            flu1appointmenttimeslot: '',
            flu1appointmenttype: '',
            seenvaccineinfo: '',
            consentshare: '',
            confirmbringmedlist: '',
            childbearingage: '',
            confirmreadpregnancyleaflet: '',
            reason: '',
            screenedforfirstapp: '',
            confirmedconsentforfirstapp: '',
            assessornameforfirstapp: '',
            outcomeforfirstapp: '',
            commentsforfirstapp: '',
            screenedforsecondapp: '',
            confirmedconsentforsecondapp: '',
            assessornameforsecondapp: '',
            outcomeforsecondapp: '',
            commentsforsecondapp: '',
            notimmunisedreason: '',
            adversereaction: '',
            
            allMandatoryAnswered: false,
            carehomeOrganisationList: [],
            organisationList: [],
            directorateList: [],
            jobRoleList: [],
            genderList: [],
            ethnicityList: [],
            firstAppointmentScheduleList: [],
            firstAppointmentSiteList: [],
            firstAppointmentLocationList: [],
            firstAppointmentSlotList: [],
            secondAppointmentScheduleList: [],
            secondAppointmentLocationList: [],
            secondAppointmentSlotList: [],
            thirdAppointmentScheduleList: [],
            thirdAppointmentSiteList: [],
            thirdAppointmentLocationList: [],
            thirdAppointmentSlotList: [],
            boost1AppointmentScheduleList: [],
            boost1AppointmentSiteList: [],
            boost1AppointmentLocationList: [],
            boost1AppointmentSlotList: [],
            flu1AppointmentScheduleList: [],
            flu1AppointmentSiteList: [],
            flu1AppointmentLocationList: [],
            flu1AppointmentSlotList: [],
            firstAppointmentOutcomeReasonsList: [],
            secondAppointmentOutcomeReasonsList: [],
            thirdAppointmentOutcomeReasonsList: [],
            boost1AppointmentOutcomeReasonsList: [],
            flu1AppointmentOutcomeReasonsList: [],
            availableVaccineList: [],
            defaultSecondVaccinationDate: '',
            defaultThirdVaccinationDate: '',
            inEdit: [],

            showingFirstAppScreeningQuestions: true,
            showingFirstAppAdminFields: true,
            showingSecondAppAdminFields: false,
            showingFirstPsd: false,
            showingSecondPsd: false,
            showingThirdPsd: false,
            showingBoosterPsd: false,
            showingFluPsd: false,
            viewAsPsd: viewAsPsd,
            isInvalid: false,
            completed: false,
            errored: false,
            saved: false,
            showDeleteConfirm: false,
            submitting: false,
            questions: [
                { key: "vip", mandatory: false },
                { key: "eligible", mandatory: true },
                { key: "healthcareworker", mandatory: true },
                { key: "carehomeworker", mandatory: false },
                { key: "carehomeorganisation", mandatory: false },
                { key: "onbehalfof", mandatory: true },
                { key: "onbehalfofrelationship", mandatory: true },
                { key: "onbehalfofname", mandatory: true },
                { key: "willingtopartake", mandatory: true },

                { key: "infectionorfeverish", mandatory: false },
                { key: "covidpositive4weeks", mandatory: false },
                { key: "othervaccinepast14days", mandatory: false },
                { key: "othervaccinedate", mandatory: false },
                { key: "flujabplanned", mandatory: false },
                { key: "flujabplanneddate", mandatory: false },
                { key: "seriousreaction", mandatory: false },
                { key: "seriousreactionconfirmed", mandatory: false },
                { key: "allergic", mandatory: false },
                { key: "allergicconfirmed", mandatory: false },
                { key: "bleedingdisorder", mandatory: false },
                { key: "bleedingdisorderconfirmed", mandatory: false },
                { key: "warfarin", mandatory: false },
                { key: "warfarinthresholdok", mandatory: false },
                { key: "pregnant", mandatory: false },
                { key: "covidtrial", mandatory: false },
                { key: "covidtrialapproved", mandatory: false },
                { key: "confirmflujabrestrictions", mandatory: false },
                { key: "vaccinetype", mandatory: false },

                { key: "infectionorfeverish2", mandatory: false },
                { key: "covidpositive4weeks2", mandatory: false },
                { key: "othervaccinepast14days2", mandatory: false },
                { key: "othervaccinedate2", mandatory: false },
                { key: "flujabplanned2", mandatory: false },
                { key: "flujabplanneddate2", mandatory: false },
                { key: "seriousreaction2", mandatory: false },
                { key: "seriousreactionconfirmed2", mandatory: false },
                { key: "allergic2", mandatory: false },
                { key: "allergicconfirmed2", mandatory: false },
                { key: "bleedingdisorder2", mandatory: false },
                { key: "bleedingdisorderconfirmed2", mandatory: false },
                { key: "pregnant2", mandatory: false },
                { key: "covidtrial2", mandatory: false },
                { key: "covidtrialapproved2", mandatory: false },
                { key: "confirmflujabrestrictions2", mandatory: false },
                { key: "vaccinetype2", mandatory: false },

                { key: "nhsnumber", mandatory: false },
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: false },
                { key: "gender", mandatory: false },
                { key: "postcode", mandatory: false },
                { key: "ethnicity", mandatory: false },
                { key: "mobile", mandatory: false },
                { key: "email", mandatory: false },
                { key: "organisation", mandatory: true },
                { key: "directorate", mandatory: true },
                { key: "jobrole", mandatory: true },
                { key: "departmentward", mandatory: true },
                { key: "assignmentnumber", mandatory: false },
                { key: "address", mandatory: false },
                { key: "firstappointmentsite", mandatory: false },
                { key: "firstappointmentlocation", mandatory: false },
                { key: "firstappointmentdate", mandatory: false },
                { key: "firstappointmenttimeslot", mandatory: false },
                { key: "secondappointmentsite", mandatory: false },
                { key: "secondappointmentlocation", mandatory: false },
                { key: "secondappointmentdate", mandatory: false },
                { key: "secondappointmenttimeslot", mandatory: false },
                { key: "seenvaccineinfo", mandatory: false },
                { key: "consentshare", mandatory: false },
                { key: "confirmbringmedlist", mandatory: false },
                { key: "childbearingage", mandatory: false },
                { key: "confirmreadpregnancyleaflet", mandatory: false },
                { key: "reason", mandatory: false },
                { key: "confirm", mandatory: true },
            ],
            validationErrors: []
        };

        this.getVaccines = this.getVaccines.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.renderAnswer = this.renderAnswer.bind(this);
        this.renderEligibleAnswer = this.renderEligibleAnswer.bind(this);
        this.getCareHomeOrganisation = this.getCareHomeOrganisation.bind(this);
        this.getAnswersForStorage = this.getAnswersForStorage.bind(this);
        this.getSecondBookingWindowStart = this.getSecondBookingWindowStart.bind(this);
        this.editField = this.editField.bind(this);
        this.isEditing = this.isEditing.bind(this);
        this.getNewQuestions = this.getNewQuestions.bind(this);
        this.isMandatory = this.isMandatory.bind(this);
        this.showDeleteSubmission = this.showDeleteSubmission.bind(this);
        this.hideDeleteSubmission = this.hideDeleteSubmission.bind(this);
        this.deleteSubmission = this.deleteSubmission.bind(this);
        this.updateAdminFields = this.updateAdminFields.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.dateMatch = this.dateMatch.bind(this);
        this.availableDateForOtherVaccineJab = this.availableDateForOtherVaccineJab.bind(this);
        this.availableDateForFirstVaccination = this.availableDateForFirstVaccination.bind(this);
        this.availableDateForSecondVaccination = this.availableDateForSecondVaccination.bind(this);
        this.availableDateForThirdVaccination = this.availableDateForThirdVaccination.bind(this);
        this.availableDateForBoosterVaccination = this.availableDateForBoosterVaccination.bind(this);
        this.availableDateForFluVaccination = this.availableDateForFluVaccination.bind(this);
        this.getCurrentSchedule = this.getCurrentSchedule.bind(this);
        this.getAvailableSchedules = this.getAvailableSchedules.bind(this);
        this.getSchedulesForFirstAppointment = this.getSchedulesForFirstAppointment.bind(this);
        this.getSchedulesForFollowUpAppointment = this.getSchedulesForFollowUpAppointment.bind(this);
        this.getSchedulesForThirdAppointment = this.getSchedulesForThirdAppointment.bind(this);
        this.getSchedulesForBoosterAppointment = this.getSchedulesForBoosterAppointment.bind(this);
        this.getAvailableSites = this.getAvailableSites.bind(this);
        this.getVaccineFromLocation = this.getVaccineFromLocation.bind(this);
        this.getVaccineFullName = this.getVaccineFullName.bind(this);
        this.getAvailableLocations = this.getAvailableLocations.bind(this);
        this.getAvailableSitesForFirstAppointment = this.getAvailableSitesForFirstAppointment.bind(this);
        this.getAvailableSitesForSecondAppointment = this.getAvailableSitesForSecondAppointment.bind(this);
        this.getAvailableSitesForThirdAppointment = this.getAvailableSitesForThirdAppointment.bind(this);
        this.getAvailableSitesForBoosterAppointment = this.getAvailableSitesForBoosterAppointment.bind(this);
        this.getAvailableSitesForFluAppointment = this.getAvailableSitesForFluAppointment.bind(this);
        this.getAvailableLocationsForFirstAppointment = this.getAvailableLocationsForFirstAppointment.bind(this);
        this.getAvailableLocationsForSecondAppointment = this.getAvailableLocationsForSecondAppointment.bind(this)
        this.getAvailableLocationsForThirdAppointment = this.getAvailableLocationsForThirdAppointment.bind(this)
        this.getAvailableLocationsForBoosterAppointment = this.getAvailableLocationsForBoosterAppointment.bind(this)
        this.getAvailableLocationsForFluAppointment = this.getAvailableLocationsForFluAppointment.bind(this)
        this.getAvailableTimeSlots = this.getAvailableTimeSlots.bind(this);
        this.getAvailableTimeSlotsForFirstAppointment = this.getAvailableTimeSlotsForFirstAppointment.bind(this);
        this.getAvailableTimeSlotsForSecondAppointment = this.getAvailableTimeSlotsForSecondAppointment.bind(this);
        this.getAvailableTimeSlotsForThirdAppointment = this.getAvailableTimeSlotsForThirdAppointment.bind(this);
        this.getAvailableTimeSlotsForBoosterAppointment = this.getAvailableTimeSlotsForBoosterAppointment.bind(this);
        this.getAvailableTimeSlotsForFluAppointment = this.getAvailableTimeSlotsForFluAppointment.bind(this);
        this.syncFollowUpAppointmentSiteLocation = this.syncFollowUpAppointmentSiteLocation.bind(this);
        this.secondAppointmentAdminQuestionsAvailable = this.secondAppointmentAdminQuestionsAvailable.bind(this);
        this.thirdAppointmentAdminQuestionsAvailable = this.thirdAppointmentAdminQuestionsAvailable.bind(this);
        this.boost1AppointmentAdminQuestionsAvailable = this.boost1AppointmentAdminQuestionsAvailable.bind(this);
        this.flu1AppointmentAdminQuestionsAvailable = this.flu1AppointmentAdminQuestionsAvailable.bind(this);
        this.showFirstAppointmentScreeningQuestions = this.showFirstAppointmentScreeningQuestions.bind(this);
        this.hideFirstAppointmentScreeningQuestions = this.hideFirstAppointmentScreeningQuestions.bind(this);
        this.showFirstAppAdminFields = this.showFirstAppAdminFields.bind(this);
        this.hideFirstAppAdminFields = this.hideFirstAppAdminFields.bind(this);
        this.showFollowUpAdminFields = this.showFollowUpAdminFields.bind(this);
        this.hideFollowUpAdminFields = this.hideFollowUpAdminFields.bind(this);
        this.toggleShowFirstPsd = this.toggleShowFirstPsd.bind(this);
        this.toggleShowSecondPsd = this.toggleShowSecondPsd.bind(this);
        this.toggleShowThirdPsd = this.toggleShowThirdPsd.bind(this);
        this.toggleShowBoosterPsd = this.toggleShowBoosterPsd.bind(this);
        this.toggleShowFluPsd = this.toggleShowFluPsd.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.textChangeNoStoreHandler = this.textChangeNoStoreHandler.bind(this);
        this.textChangeRawHandler = this.textChangeRawHandler.bind(this);
        this.textChangeNoStoreRawHandler = this.textChangeNoStoreRawHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.checkedChangeHandler = this.checkedChangeHandler.bind(this);
        this.checkedChangeNoStoreHandler = this.checkedChangeNoStoreHandler.bind(this);
        this.dateOfOtherVaccineChangeHandler = this.dateOfOtherVaccineChangeHandler.bind(this);
        this.dateOfOtherVaccine2ChangeHandler = this.dateOfOtherVaccine2ChangeHandler.bind(this);
        this.dateOfFirstVaccinationChangeHandler = this.dateOfFirstVaccinationChangeHandler.bind(this);
        this.dateOfSecondVaccinationChangeHandler = this.dateOfSecondVaccinationChangeHandler.bind(this);
        this.dateOfThirdVaccinationChangeHandler = this.dateOfThirdVaccinationChangeHandler.bind(this);
        this.dateOfBoosterVaccinationChangeHandler = this.dateOfBoosterVaccinationChangeHandler.bind(this);
        this.dateOfFluVaccinationChangeHandler = this.dateOfFluVaccinationChangeHandler.bind(this);
        this.isValidNhsNumber = this.isValidNhsNumber.bind(this);
        this.isPaediatricCohort = this.isPaediatricCohort.bind(this);
        this.isFiveToElevenCohort = this.isFiveToElevenCohort.bind(this);
        this.isOnlyEligibleForSingleAppointment = this.isOnlyEligibleForSingleAppointment.bind(this);
        this.showIf = this.showIf.bind(this);
        this.pad = this.pad.bind(this);
        this.renderPsd = this.renderPsd.bind(this);
        this.rawChangeHandler = this.rawChangeHandler.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const availableDirectorates = questionManager.getDirectorates();
        const availableJobRoles = questionManager.getJobRoles();
        const availableGenders = questionManager.getGenders();
        const availableEthnicities = questionManager.getEthnicities();
        const availableFirstAppReasons = questionManager.getFirstAppointmentOutcomeReasons();
        const availableSecondAppReasons = questionManager.getSecondAppointmentOutcomeReasons();
        const availableThirdAppReasons = questionManager.getThirdAppointmentOutcomeReasons();
        const availableBoosterAppReasons = questionManager.getBoosterAppointmentOutcomeReasons();
        const availableFluAppReasons = questionManager.getFluAppointmentOutcomeReasons();
        const availableOrganisations = questionManager.getOrganisations();
        const availableCarehomeOrganisations = questionManager.getCarehomeOrganisations();
        const availableVaccines = await this.getVaccines();
        const availableSchedulesForFirstAppointment = await this.getSchedulesForFirstAppointment();

        let mappedAnswers = {};
        const existingAnswers = this.state.answers.filter(a => a.key !== 'vaccinetype' && a.key !== 'vaccinetype2' && a.key !== 'vaccinetype3' && a.key !== 'vaccinetypeboost1' && a.key !== 'firstappointmenttype' && a.key !== 'secondappointmenttype' && a.key !== 'thirdappointmenttype' && a.key !== 'boost1appointmenttype');
        const vaccineAnswers = this.state.answers.filter(a => a.key === 'vaccinetype' || a.key === 'vaccinetype2' || a.key === 'vaccinetype3' || a.key === 'vaccinetypeboost1' || a.key === 'firstappointmenttype' || a.key === 'secondappointmenttype' || a.key === 'thirdappointmenttype' || a.key === 'boost1appointmenttype');
        this.state.answers.map(a => {
            mappedAnswers[a.key] = a.answer;
            if (a.key === 'othervaccinedate' || a.key === 'flujabplanneddate' || a.key === 'firstappointmentdate' || a.key === 'secondappointmentdate' || a.key === 'thirdappointmentdate' || a.key === 'boost1appointmentdate' || a.key === 'flu1appointmentdate') {
                if (a.answer) {
                    const dateParts = a.answer.split('/');
                    const answerAsDate = new Date(new Date().toDateString());
                    answerAsDate.setFullYear(dateParts[2]);
                    answerAsDate.setMonth(parseInt(dateParts[1]) - 1);
                    answerAsDate.setDate(dateParts[0]);
                    mappedAnswers[a.key] = answerAsDate;
                }
            }
        });

        if (mappedAnswers.warfarin === 'Yes') { // Merge warfarin answer in to more general bleeding disorder answer, which now represents both.
            mappedAnswers.bleedingdisorder = 'Yes';
        }
        if (!mappedAnswers.vip) {
            mappedAnswers.vip = 'No';
            existingAnswers.push({ key: 'vip', answer: 'No' })
        }
        if (mappedAnswers.thirdappointmentdate) {
            mappedAnswers.defaultThirdVaccinationDate = new Date(mappedAnswers.thirdappointmentdate.toString());
        } else if (mappedAnswers.secondappointmentdate) {

            let thirdWindowBookingStart = defaultThirdBookingWindowStart;
            if (mappedAnswers.eligible) {
                const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === mappedAnswers.eligible);
                if (newBookingWindow) {
                    thirdWindowBookingStart = newBookingWindow.weeks;
                }
            }

            let defaultThirdVaccinationDate = new Date(mappedAnswers.secondappointmentdate.toDateString());
            defaultThirdVaccinationDate.setDate(defaultThirdVaccinationDate.getDate() + ((thirdWindowBookingStart * 7) - 7));
            mappedAnswers.defaultThirdVaccinationDate = new Date(defaultThirdVaccinationDate.toDateString());
        }
        if (mappedAnswers.secondappointmentdate) {
            mappedAnswers.defaultSecondVaccinationDate = new Date(mappedAnswers.secondappointmentdate.toString());
        } else if (mappedAnswers.firstappointmentdate) {

            let secondWindowBookingStart = defaultSecondBookingWindowStart;
            if (mappedAnswers.eligible) {
                const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === mappedAnswers.eligible);
                if (newBookingWindow) {
                    secondWindowBookingStart = newBookingWindow.weeks;
                }
            }

            let defaultSecondVaccinationDate = new Date(mappedAnswers.firstappointmentdate.toDateString());
            defaultSecondVaccinationDate.setDate(defaultSecondVaccinationDate.getDate() + ((secondWindowBookingStart * 7) - 7));
            mappedAnswers.defaultSecondVaccinationDate = new Date(defaultSecondVaccinationDate.toDateString());
        }

        // If first app date matches today or is later than today...
        if (this.dateMatch(mappedAnswers.firstappointmentdate, currentDate) || mappedAnswers.firstappointmentdate > currentDate) {
            const firstAppSchedule = await this.getCurrentSchedule((this.state.viewAsPsd ? currentDate : mappedAnswers.firstappointmentdate), mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation);
            let secondAppSchedule;
            if (mappedAnswers.secondappointmentdate && mappedAnswers.secondappointmentsite && mappedAnswers.secondappointmentlocation) {
                secondAppSchedule = await this.getCurrentSchedule(mappedAnswers.secondappointmentdate, mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation);
            }
            
            const existingVaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetype');
            // ... and also we haven't yet screened (or no previous record), then use vaccine type configured at site location today.
            if ((mappedAnswers.screenedforfirstapp !== "Yes" && !mappedAnswers.prescribertimestamp) || !(existingVaccineAnswer && existingVaccineAnswer.answer)) {
                const firstAppVaccine = firstAppSchedule ? this.getVaccineFromLocation([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, (this.state.viewAsPsd ? currentDate : mappedAnswers.firstappointmentdate)) : '';
                const secondAppVaccine = secondAppSchedule ? this.getVaccineFromLocation([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : '';
                const firstAppType = firstAppSchedule ? this.getAppointmentType([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, (this.state.viewAsPsd ? currentDate : mappedAnswers.firstappointmentdate)) : false;
                const secondAppType = secondAppSchedule ? this.getAppointmentType([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : firstAppType;
                mappedAnswers.vaccinetype = firstAppVaccine;
                mappedAnswers.vaccinetype2 = secondAppVaccine; // ensure the follow up vaccine matches
                mappedAnswers.firstappointmenttype = firstAppType;
                mappedAnswers.secondappointmenttype = secondAppType;
                mappedAnswers.allowedVaccineType = firstAppVaccine;
                existingAnswers.push({ key: 'vaccinetype', answer: firstAppVaccine }, { key: 'vaccinetype2', answer: secondAppVaccine }, { key: 'firstappointmenttype', answer: mappedAnswers.firstappointmenttype }, { key: 'secondappointmenttype', answer: mappedAnswers.secondappointmenttype });
            } else {
                vaccineAnswers.map(a => {
                    mappedAnswers[a.key] = a.answer;
                });
                if ((mappedAnswers.screenedforsecondapp !== "Yes" && !mappedAnswers.prescribertimestamp2)) {
                    const secondAppVaccine = secondAppSchedule ? this.getVaccineFromLocation([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : '';
                    const secondAppType = secondAppSchedule ? this.getAppointmentType([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : 'Adult';
                    mappedAnswers.vaccinetype2 = secondAppVaccine;
                    mappedAnswers.secondappointmenttype = secondAppType;
                }
                existingAnswers.push(...vaccineAnswers);
            }
        } else if (this.dateMatch(mappedAnswers.secondappointmentdate, currentDate) || mappedAnswers.secondappointmentdate > currentDate) {
            if ((mappedAnswers.screenedforsecondapp !== "Yes" && !mappedAnswers.prescribertimestamp2)) {
                let firstAppSchedule;
                let secondAppSchedule;
                if (mappedAnswers.firstappointmentdate) {
                    firstAppSchedule = await this.getCurrentSchedule(mappedAnswers.firstappointmentdate, mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation);
                }
                if (mappedAnswers.secondappointmentdate) {
                    secondAppSchedule = await this.getCurrentSchedule(currentDate, mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation);
                }

                const firstAppVaccine = firstAppSchedule ? this.getVaccineFromLocation([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, mappedAnswers.firstappointmentdate) : '';
                const secondAppVaccine = secondAppSchedule ? this.getVaccineFromLocation([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : '';
                const firstAppType = firstAppSchedule ? this.getAppointmentType([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, mappedAnswers.firstappointmentdate) : 'Adult';
                const secondAppType = secondAppSchedule ? this.getAppointmentType([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : 'Adult';
                const existingVaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetype');
                const existingFirstAppTypeAnswer = vaccineAnswers.find(a => a.key === 'firstappointmenttype');
                if (existingVaccineAnswer) {
                    if (existingVaccineAnswer.answer !== secondAppVaccine) {
                        mappedAnswers.vaccineConflict = true;
                    }
                }
                mappedAnswers.vaccinetype = (existingVaccineAnswer && existingVaccineAnswer.answer) || firstAppVaccine; // Use first vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.vaccinetype2 = secondAppVaccine; // Use first vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.firstappointmenttype = (existingFirstAppTypeAnswer && existingFirstAppTypeAnswer.answer) || firstAppType;
                mappedAnswers.secondappointmenttype = secondAppType;
                mappedAnswers.allowedVaccineType = mappedAnswers.vaccinetype;
                existingAnswers.push({ key: 'vaccinetype', answer: mappedAnswers.vaccinetype }, { key: 'vaccinetype2', answer: mappedAnswers.vaccinetype2 }, { key: 'firstappointmenttype', answer: mappedAnswers.firstappointmenttype }, { key: 'secondappointmenttype', answer: mappedAnswers.secondappointmenttype });
            } else {
                vaccineAnswers.map(a => {
                    mappedAnswers[a.key] = a.answer;
                });
                existingAnswers.push(...vaccineAnswers);
            }
        } else if (this.dateMatch(mappedAnswers.thirdappointmentdate, currentDate) || mappedAnswers.thirdappointmentdate > currentDate) {
            if ((mappedAnswers.screenedforthirdapp !== "Yes" && !mappedAnswers.prescribertimestamp3)) {
                let firstAppSchedule;
                let secondAppSchedule;
                let thirdAppSchedule;
                if (mappedAnswers.firstappointmentdate) {
                    firstAppSchedule = await this.getCurrentSchedule(mappedAnswers.firstappointmentdate, mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation);
                }
                if (mappedAnswers.secondappointmentdate) {
                    secondAppSchedule = await this.getCurrentSchedule(mappedAnswers.secondappointmentdate, mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation);
                }
                if (mappedAnswers.thirdappointmentdate) {
                    thirdAppSchedule = await this.getCurrentSchedule(currentDate, mappedAnswers.thirdappointmentsite, mappedAnswers.thirdappointmentlocation);
                }
                
                const firstAppVaccine = firstAppSchedule ? this.getVaccineFromLocation([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, mappedAnswers.firstappointmentdate) : '';
                const secondAppVaccine = secondAppSchedule ? this.getVaccineFromLocation([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : '';
                const thirdAppVaccine = thirdAppSchedule ? this.getVaccineFromLocation([thirdAppSchedule], mappedAnswers.thirdappointmentsite, mappedAnswers.thirdappointmentlocation, mappedAnswers.thirdappointmentdate) : '';
                const firstAppType = firstAppSchedule ? this.getAppointmentType([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, mappedAnswers.firstappointmentdate) : 'Adult';
                const secondAppType = secondAppSchedule ? this.getAppointmentType([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : 'Adult';
                const thirdAppType = thirdAppSchedule ? this.getAppointmentType([thirdAppSchedule], mappedAnswers.thirdappointmentsite, mappedAnswers.thirdappointmentlocation, mappedAnswers.thirdappointmentdate) : 'Adult';
                const existingVaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetype');
                const existingThirdVaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetype3');
                const existingFirstAppTypeAnswer = vaccineAnswers.find(a => a.key === 'firstappointmenttype');
                if (existingVaccineAnswer) {
                    if (existingVaccineAnswer.answer !== secondAppVaccine) {
                        mappedAnswers.vaccineConflict = true;
                    }
                }
                mappedAnswers.vaccinetype = (existingVaccineAnswer && existingVaccineAnswer.answer) || firstAppVaccine; // Use first vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.vaccinetype2 = secondAppVaccine; // Use first vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.vaccinetype3 = (existingThirdVaccineAnswer && existingThirdVaccineAnswer.answer) || thirdAppVaccine; // Use first vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.firstappointmenttype = (existingFirstAppTypeAnswer && existingFirstAppTypeAnswer.answer) || firstAppType;
                mappedAnswers.secondappointmenttype = secondAppType;
                mappedAnswers.thirdappointmenttype = thirdAppType;
                mappedAnswers.allowedVaccineType = mappedAnswers.vaccinetype;
                existingAnswers.push({ key: 'vaccinetype', answer: mappedAnswers.vaccinetype }, { key: 'vaccinetype2', answer: mappedAnswers.vaccinetype2 }, { key: 'vaccinetype3', answer: mappedAnswers.vaccinetype3 }, { key: 'firstappointmenttype', answer: mappedAnswers.firstappointmenttype }, { key: 'secondappointmenttype', answer: mappedAnswers.secondappointmenttype }, { key: 'thirdappointmenttype', answer: mappedAnswers.thirdappointmenttype });
            } else {
                vaccineAnswers.map(a => {
                    mappedAnswers[a.key] = a.answer;
                });
                existingAnswers.push(...vaccineAnswers);
            }
        } else if (this.dateMatch(mappedAnswers.boost1appointmentdate, currentDate) || mappedAnswers.boost1appointmentdate > currentDate) {
            if ((mappedAnswers.screenedforboost1app !== "Yes" && !mappedAnswers.prescribertimestampboost1)) {
                let firstAppSchedule;
                let secondAppSchedule;
                let thirdAppSchedule;
                let boost1AppSchedule;
                if (mappedAnswers.firstappointmentdate) {
                    firstAppSchedule = await this.getCurrentSchedule(mappedAnswers.firstappointmentdate, mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation);
                }
                if (mappedAnswers.secondappointmentdate) {
                    secondAppSchedule = await this.getCurrentSchedule(mappedAnswers.secondappointmentdate, mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation);
                }
                if (mappedAnswers.thirdappointmentdate) {
                    thirdAppSchedule = await this.getCurrentSchedule(currentDate, mappedAnswers.thirdappointmentsite, mappedAnswers.thirdappointmentlocation);
                }
                if (mappedAnswers.boost1appointmentdate) {
                    boost1AppSchedule = await this.getCurrentSchedule(currentDate, mappedAnswers.boost1appointmentsite, mappedAnswers.boost1appointmentlocation);
                }

                const firstAppVaccine = firstAppSchedule ? this.getVaccineFromLocation([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, mappedAnswers.firstappointmentdate) : '';
                const secondAppVaccine = secondAppSchedule ? this.getVaccineFromLocation([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : '';
                const thirdAppVaccine = thirdAppSchedule ? this.getVaccineFromLocation([thirdAppSchedule], mappedAnswers.thirdappointmentsite, mappedAnswers.thirdappointmentlocation, mappedAnswers.thirdappointmentdate) : '';
                const boost1AppVaccine = boost1AppSchedule ? this.getVaccineFromLocation([boost1AppSchedule], mappedAnswers.boost1appointmentsite, mappedAnswers.boost1appointmentlocation, mappedAnswers.boost1appointmentdate) : '';
                const firstAppType = firstAppSchedule ? this.getAppointmentType([firstAppSchedule], mappedAnswers.firstappointmentsite, mappedAnswers.firstappointmentlocation, mappedAnswers.firstappointmentdate) : 'Adult';
                const secondAppType = secondAppSchedule ? this.getAppointmentType([secondAppSchedule], mappedAnswers.secondappointmentsite, mappedAnswers.secondappointmentlocation, mappedAnswers.secondappointmentdate) : 'Adult';
                const thirdAppType = thirdAppSchedule ? this.getAppointmentType([thirdAppSchedule], mappedAnswers.thirdappointmentsite, mappedAnswers.thirdappointmentlocation, mappedAnswers.thirdappointmentdate) : 'Adult';
                const boost1AppType = boost1AppSchedule ? this.getAppointmentType([boost1AppSchedule], mappedAnswers.boost1appointmentsite, mappedAnswers.boost1appointmentlocation, mappedAnswers.boost1appointmentdate) : 'Adult';
                const existingVaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetype');
                const existingSecondVaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetype2');
                const existingThirdVaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetype3');
                const existingBoost1VaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetypeboost1');
                const existingFirstAppTypeAnswer = vaccineAnswers.find(a => a.key === 'firstappointmenttype');
                const existingSecondAppTypeAnswer = vaccineAnswers.find(a => a.key === 'secondappointmenttype');
                const existingThirdAppTypeAnswer = vaccineAnswers.find(a => a.key === 'thirdappointmenttype');
                if (existingVaccineAnswer) {
                    if (existingVaccineAnswer.answer !== secondAppVaccine) {
                        mappedAnswers.vaccineConflict = true;
                    }
                }
                mappedAnswers.vaccinetype = (existingVaccineAnswer && existingVaccineAnswer.answer) || firstAppVaccine; // Use first vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.vaccinetype2 = (existingSecondVaccineAnswer && existingSecondVaccineAnswer.answer) || secondAppVaccine; ; // Use second vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.vaccinetype3 = (existingThirdVaccineAnswer && existingThirdVaccineAnswer.answer) || thirdAppVaccine; // Use third vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.vaccinetypeboost1 = (existingBoost1VaccineAnswer && existingBoost1VaccineAnswer.answer) || boost1AppVaccine; // Use booster vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.firstappointmenttype = (existingFirstAppTypeAnswer && existingFirstAppTypeAnswer.answer) || firstAppType;
                mappedAnswers.secondappointmenttype = (existingSecondAppTypeAnswer && existingSecondAppTypeAnswer.answer) || secondAppType;
                mappedAnswers.thirdappointmenttype = (existingThirdAppTypeAnswer && existingThirdAppTypeAnswer.answer) || thirdAppType;
                mappedAnswers.boost1appointmenttype = boost1AppType;
                mappedAnswers.allowedVaccineType = mappedAnswers.vaccinetype;
                existingAnswers.push({ key: 'vaccinetype', answer: mappedAnswers.vaccinetype }, { key: 'vaccinetype2', answer: mappedAnswers.vaccinetype2 }, { key: 'vaccinetype3', answer: mappedAnswers.vaccinetype3 }, { key: 'vaccinetypeboost1', answer: mappedAnswers.vaccinetypeboost1 }, { key: 'firstappointmenttype', answer: mappedAnswers.firstappointmenttype }, { key: 'secondappointmenttype', answer: mappedAnswers.secondappointmenttype }, { key: 'thirdappointmenttype', answer: mappedAnswers.thirdappointmenttype }, { key: 'boost1appointmenttype', answer: mappedAnswers.boost1appointmenttype });
            } else {
                vaccineAnswers.map(a => {
                    mappedAnswers[a.key] = a.answer;
                });
                existingAnswers.push(...vaccineAnswers);
            }
        }
        if (this.dateMatch(mappedAnswers.flu1appointmentdate, currentDate) || mappedAnswers.flu1appointmentdate > currentDate) {
            if ((mappedAnswers.screenedforflu1app !== "Yes" && !mappedAnswers.prescribertimestampflu1)) {
                let flu1AppSchedule;
                if (mappedAnswers.flu1appointmentdate) {
                    flu1AppSchedule = await this.getCurrentSchedule(currentDate, mappedAnswers.flu1appointmentsite, mappedAnswers.flu1appointmentlocation);
                }

                const flu1AppVaccine = flu1AppSchedule ? this.getVaccineFromLocation([flu1AppSchedule], mappedAnswers.flu1appointmentsite, mappedAnswers.flu1appointmentlocation, mappedAnswers.flu1appointmentdate, true) : '';
                const flu1AppType = flu1AppSchedule ? this.getAppointmentType([flu1AppSchedule], mappedAnswers.flu1appointmentsite, mappedAnswers.flu1appointmentlocation, mappedAnswers.flu1appointmentdate) : 'Adult';
                const existingFlu1VaccineAnswer = vaccineAnswers.find(a => a.key === 'vaccinetypeflu1');
                mappedAnswers.vaccinetypeflu1 = (existingFlu1VaccineAnswer && existingFlu1VaccineAnswer.answer) || flu1AppVaccine; // Use flu vaccine type, or use vaccine from today's schedule location if not recorded.
                mappedAnswers.flu1appointmenttype = flu1AppType;
                existingAnswers.push({ key: 'vaccinetypeflu1', answer: mappedAnswers.vaccinetypeflu1 }, { key: 'flu1appointmenttype', answer: mappedAnswers.flu1appointmenttype });
            } else {
                vaccineAnswers.map(a => {
                    mappedAnswers[a.key] = a.answer;
                });
                existingAnswers.push(...vaccineAnswers);
            }
        }

        this.setState({
            initialised: true,
            carehomeOrganisationList: availableCarehomeOrganisations,
            directorateList: availableDirectorates,
            jobRoleList: availableJobRoles,
            genderList: availableGenders,
            ethnicityList: availableEthnicities,
            firstAppointmentOutcomeReasonsList: availableFirstAppReasons,
            secondAppointmentOutcomeReasonsList: availableSecondAppReasons,
            thirdAppointmentOutcomeReasonsList: availableThirdAppReasons,
            boost1AppointmentOutcomeReasonsList: availableBoosterAppReasons,
            flu1AppointmentOutcomeReasonsList: availableFluAppReasons,
            organisationList: availableOrganisations,
            availableVaccineList: availableVaccines,
            showingFirstAppScreeningQuestions: false,
            showingFirstAppAdminFields: (!mappedAnswers.firstappointmentdate || (mappedAnswers.firstappointmentdate && mappedAnswers.secondappointmentdate && mappedAnswers.secondappointmentdate > currentDate)),
            showingSecondAppAdminFields: (mappedAnswers.secondappointmentdate && mappedAnswers.secondappointmentdate <= currentDate) || mappedAnswers.outcomeforsecondapp,
            originalScreenedForFirstApp: mappedAnswers.screenedforfirstapp,
            originalScreenedForSecondApp: mappedAnswers.screenedforsecondapp,
            answers: existingAnswers,
            ...mappedAnswers
        }, function () {
            if (this.state.firstappointmentsite && this.state.firstappointmentlocation && this.state.firstappointmentdate) {
                this.getSchedulesForFirstAppointment(() => {
                    this.getSchedulesForFollowUpAppointment();
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { errorSaving, saved } = this.props;
        if ((prevProps.errorSaving !== errorSaving && errorSaving !== this.state.errored) || saved !== this.state.saved) {
            this.setState({
                errored: errorSaving,
                saved: saved,
                submitting: false
            });
        }
    }

    async getVaccines() {
        try {
            const response = await fetch('/ScheduleVaccine', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            }
        }
        catch (err) {
            console.log(err);
        }
        return [];
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    dateMatch(firstDate, secondDate) {
        if (!firstDate && !secondDate) {
            return true;
        }

        if (!firstDate || !secondDate) {
            return false;
        }

        if (firstDate.getDate() !== secondDate.getDate()) {
            return false;
        }
        if (firstDate.getMonth() !== secondDate.getMonth()) {
            return false;
        }
        if (firstDate.getFullYear() !== secondDate.getFullYear()) {
            return false;
        }
        return true;
    }

    availableDateForOtherVaccineJab(theDate) {
        let invalidDate = false;
        const availabilityStartDate = new Date();
        availabilityStartDate.setHours(0);
        availabilityStartDate.setMinutes(0);
        availabilityStartDate.setSeconds(0);
        availabilityStartDate.setMilliseconds(0);
        availabilityStartDate.setDate(availabilityStartDate.getDate() - 7);
        if (theDate < availabilityStartDate || theDate > new Date()) {
            invalidDate = true;
        }
        return !invalidDate;
    }

    availableDateForFirstVaccination(theDate, ignoreSchedule) {
        const availabilityStartDate = new Date(new Date().toDateString());
        const availabilityEndDate = new Date(new Date().toDateString());

        availabilityEndDate.setDate(availabilityEndDate.getDate() + 24);

        let invalidDate = false;
        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            if (!this.state.firstAppointmentDateOverride) {
                invalidDate = true;
            }
        }
        if (this.state.othervaccinedate) {
            let earliestVaccinationDate = new Date(this.state.othervaccinedate.getFullYear(), this.state.othervaccinedate.getMonth(), this.state.othervaccinedate.getDate());
            earliestVaccinationDate.setDate(earliestVaccinationDate.getDate() + 8);
            if (theDate < earliestVaccinationDate) {
                invalidDate = true;
            }
        }

        if (this.state.flujabplanneddate) {
            let startOfRestrictedPeriod = new Date(this.state.flujabplanneddate.getFullYear(), this.state.flujabplanneddate.getMonth(), this.state.flujabplanneddate.getDate());
            let endOfRestrictedPeriod = new Date(this.state.flujabplanneddate.getFullYear(), this.state.flujabplanneddate.getMonth(), this.state.flujabplanneddate.getDate());
            startOfRestrictedPeriod.setDate(startOfRestrictedPeriod.getDate() - 15); // was 8
            endOfRestrictedPeriod.setDate(endOfRestrictedPeriod.getDate() + 15); // was 8
            if (theDate > startOfRestrictedPeriod && theDate < endOfRestrictedPeriod) {
                invalidDate = true;
            }
        }
        if (!ignoreSchedule) {
            let availableSchedules = this.state.firstAppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));

            if (this.state.firstappointmentsite && this.state.firstappointmentlocation) {
                availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.firstappointmentsite && s.scheduleLocation.locationName === this.state.firstappointmentlocation);
            }
            if (availableSchedules.length === 0) {
                invalidDate = true;
            }
        }

        return !invalidDate;
    }

    availableDateForSecondVaccination(theDate, ignoreSchedule) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (this.state.secondappointmentonly === "Yes") {
            availabilityStartDate = new Date(currentDate.toDateString());
            availabilityEndDate = new Date(currentDate.toDateString());
            availabilityStartDate.setDate(availabilityStartDate.getDate());
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (secondWindowBookingStart * 7)); // was 84
        } else {
            availabilityStartDate = new Date(this.state.firstappointmentdate.getFullYear(), this.state.firstappointmentdate.getMonth(), this.state.firstappointmentdate.getDate());
            availabilityEndDate = new Date(this.state.firstappointmentdate.getFullYear(), this.state.firstappointmentdate.getMonth(), this.state.firstappointmentdate.getDate());
            availabilityStartDate.setDate(availabilityStartDate.getDate() + ((secondWindowBookingStart * 7) - 7)); // was 77
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (secondWindowBookingStart * 7)); // was 84
        }

        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            if (!this.state.secondAppointmentDateOverride) {
                invalidDate = true;
            }
        }
        if (theDate < this.state.firstappointmentdate) {
            invalidDate = true;
        }
        if (!ignoreSchedule) {
            let availableSchedules = this.state.secondAppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));

            if (this.state.secondappointmentsite && this.state.secondappointmentlocation) {
                availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.secondappointmentsite && s.scheduleLocation.locationName === this.state.secondappointmentlocation);
            }
            if (availableSchedules.length === 0) {
                invalidDate = true;
            }
        }

        return !invalidDate;
    }

    availableDateForThirdVaccination(theDate) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        let thirdWindowBookingStart = defaultThirdBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                thirdWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (this.state.thirdappointmentonly === "Yes") {
            availabilityStartDate = new Date(currentDate.toDateString());
            availabilityEndDate = new Date(currentDate.toDateString());
            availabilityStartDate.setDate(availabilityStartDate.getDate());
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (thirdWindowBookingStart * 7));
        } else {
            availabilityStartDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityEndDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityStartDate.setDate(availabilityStartDate.getDate() + ((thirdWindowBookingStart * 7) - 7));
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (thirdWindowBookingStart * 7));
        }

        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            if (!this.state.thirdAppointmentDateOverride) {
                invalidDate = true;
            }
        }
        if (theDate < this.state.secondappointmentdate) {
            invalidDate = true;
        }
        let availableSchedules = this.state.thirdAppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.thirdappointmentsite && this.state.thirdappointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.thirdappointmentsite && s.scheduleLocation.locationName === this.state.thirdappointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    availableDateForBoosterVaccination(theDate) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        let boosterWindowBookingStart = defaultBoosterBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = boosterBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                boosterWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (this.state.thirdappointmentonly === "Yes") {
            availabilityStartDate = new Date(currentDate.toDateString());
            availabilityEndDate = new Date(currentDate.toDateString());
            availabilityStartDate.setDate(availabilityStartDate.getDate());
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (boosterWindowBookingStart * 7));
        } else {
            availabilityStartDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityEndDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityStartDate.setDate(availabilityStartDate.getDate() + ((boosterWindowBookingStart * 7) - 7));
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (boosterWindowBookingStart * 7));
        }

        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            if (!this.state.boost1AppointmentDateOverride) {
                invalidDate = true;
            }
        }
        if (theDate < this.state.secondappointmentdate) {
            invalidDate = true;
        }
        let availableSchedules = this.state.boost1AppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.boost1appointmentsite && this.state.boost1appointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.boost1appointmentsite && s.scheduleLocation.locationName === this.state.boost1appointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    availableDateForFluVaccination(theDate) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        
        availabilityStartDate = new Date(currentDate.toDateString());
        availabilityEndDate = new Date(currentDate.toDateString());
        availabilityStartDate.setDate(availabilityStartDate.getDate());
        availabilityEndDate.setDate(availabilityEndDate.getDate() + 60);
        
        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            if (!this.state.flu1AppointmentDateOverride) {
                invalidDate = true;
            }
        }

        let availableSchedules = this.state.flu1AppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.flu1appointmentsite && this.state.flu1appointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.flu1appointmentsite && s.scheduleLocation.locationName === this.state.flu1appointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    async getCurrentSchedule(scheduleDate, site, location) {
        if (!scheduleDate) {
            return null;
        }

        const scheduleDateAsString = `${scheduleDate.getFullYear()}-${this.pad(scheduleDate.getMonth() + 1, 2)}-${this.pad(scheduleDate.getDate(), 2)}`;
        const response = await fetch('/Schedule/Lookup/' + encodeURIComponent(this.state.questionnaire) + '/' + encodeURIComponent(site) + '/' + encodeURIComponent(location) + '/' + encodeURIComponent(scheduleDateAsString), {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else if (response.status === 404) {
            return null;
        } else {
            return null;
        }
    }

    async getAvailableSchedules(earliestDate, latestDate, vaccine, isFlu, isAccessible, ignoreUtilisation, paediatricAppointments, fiveToElevenAppointments) {
        const earliestDateAsString = earliestDate ? (`${earliestDate.getFullYear()}-${this.pad(earliestDate.getMonth() + 1, 2)}-${this.pad(earliestDate.getDate(), 2)}`) : '';
        const latestDateAsString = latestDate ? (`${latestDate.getFullYear()}-${this.pad(latestDate.getMonth() + 1, 2)}-${this.pad(latestDate.getDate(), 2)}`) : '';
        const response = await fetch('/Schedule?questionnaireKey=' + encodeURIComponent(this.state.questionnaire) + '&earliestDate=' + (earliestDate ? encodeURIComponent(earliestDateAsString) : '') + '&ignoreUtilisation=' + (ignoreUtilisation ? 'true' : 'false') + '&latestDate=' + (latestDate ? encodeURIComponent(latestDateAsString) : '') + '&vaccineType=' + (vaccine ? encodeURIComponent(vaccine) : '') + '&isFlu=' + (isFlu ? 'true' : 'false') + '&accessibleLocationRequired=' + (isAccessible ? 'true' : 'false') + '&paediatricAppointments=' + (paediatricAppointments ? 'true' : 'false') + '&fiveToElevenAppointments=' + (fiveToElevenAppointments ? 'true' : 'false'), {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else if (response.status === 404) {
            return [];
        } else {
            return [];
        }
    }

    async getSchedulesForFirstAppointment(callback) {
        const availabilityEndDate = new Date(new Date().toDateString());
        availabilityEndDate.setDate(availabilityEndDate.getDate() + 24); // was 27

        var availableSchedules = await this.getAvailableSchedules(this.state.firstAppointmentDateOverride ? '' : currentDate, availabilityEndDate, '', false, this.state.restrictedmobility === 'Yes' ? true : false, false, this.isPaediatricCohort(), this.isFiveToElevenCohort());
        this.setState({
            anyAppsAvailable: availableSchedules.length > 0,
            firstAppointmentScheduleList: availableSchedules
        }, function () {
            this.getAvailableSitesForFirstAppointment(callback);
        });
        return availableSchedules;
    }

    async getSchedulesForFollowUpAppointment(callback) {
        let availableSchedules;

        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
        }
        
        if (this.state.secondappointmentonly !== "Yes") {
            const earliestFollowUpDate = new Date(this.state.firstappointmentdate);
            earliestFollowUpDate.setDate(earliestFollowUpDate.getDate() + ((secondWindowBookingStart * 7) - 7));
            const latestDate = new Date(this.state.firstappointmentdate.getFullYear(), this.state.firstappointmentdate.getMonth(), this.state.firstappointmentdate.getDate());
            latestDate.setDate(latestDate.getDate() + (secondWindowBookingStart * 7));
            availableSchedules = await this.getAvailableSchedules(this.state.secondAppointmentDateOverride ? '' : earliestFollowUpDate, this.state.secondAppointmentDateOverride ? '' : latestDate, this.state.vaccinetype, false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        } else {
            availableSchedules = await this.getAvailableSchedules(this.state.secondAppointmentDateOverride ? '' : currentDate, null, this.state.vaccinetype, false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        }
        this.setState({
            anyAppsAvailable: availableSchedules.length > 0,
            secondAppointmentScheduleList: availableSchedules
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    async getSchedulesForThirdAppointment(callback) {
        let availableSchedules;

        let thirdWindowBookingStart = defaultThirdBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                thirdWindowBookingStart = newBookingWindow.weeks;
            }
        }

        const earliestFollowUpDate = this.state.secondappointmentdate ? new Date(this.state.secondappointmentdate) : new Date();
        earliestFollowUpDate.setDate(earliestFollowUpDate.getDate() + ((thirdWindowBookingStart * 7) - 7));
        if (this.state.thirdappointmentonly !== "Yes" && this.state.secondappointmentdate) {
            const latestDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            latestDate.setDate(latestDate.getDate() + (thirdWindowBookingStart * 7));
            availableSchedules = await this.getAvailableSchedules(this.state.thirdAppointmentDateOverride ? '' : earliestFollowUpDate, this.state.thirdAppointmentDateOverride ? '' : latestDate, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
        } else {
            availableSchedules = await this.getAvailableSchedules(this.state.thirdAppointmentDateOverride ? '' : currentDate, null, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        }
        this.setState({
            anyThirdAppsAvailable: availableSchedules.length > 0 && (availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)).length > 0,
            thirdAppointmentScheduleList: availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    async getSchedulesForBoosterAppointment(callback) {
        let availableSchedules;

        let boosterWindowBookingStart = defaultBoosterBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = boosterBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                boosterWindowBookingStart = newBookingWindow.weeks;
            }
        }

        const earliestFollowUpDate = this.state.secondappointmentdate ? new Date(this.state.secondappointmentdate) : new Date();
        earliestFollowUpDate.setDate(earliestFollowUpDate.getDate() + ((boosterWindowBookingStart * 7) - 7));
        if (this.state.thirdappointmentonly !== "Yes" && this.state.secondappointmentdate) {
            const latestDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            latestDate.setDate(latestDate.getDate() + (boosterWindowBookingStart * 7));
            availableSchedules = await this.getAvailableSchedules(this.state.thirdAppointmentDateOverride ? '' : earliestFollowUpDate, this.state.thirdAppointmentDateOverride ? '' : latestDate, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
        } else {
            let availablePhizerSchedules = await this.getAvailableSchedules(currentDate, null, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            let availableModernaSchedules = await this.getAvailableSchedules(currentDate, null, 'Mo', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            availableSchedules = availablePhizerSchedules.concat(availableModernaSchedules);          
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        }
        this.setState({
            anyBoosterAppsAvailable: availableSchedules.length > 0 && (availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)).length > 0,
            boost1AppointmentScheduleList: availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    async getSchedulesForFluAppointment(callback) {
        let availableSchedules;
        availableSchedules = await this.getAvailableSchedules(currentDate, null, 'Flu', true, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
        // restrict vaccines
        var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
        if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
            availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.fluVaccine === rv.vaccineCode));
        }
        this.setState({
            anyFluAppsAvailable: availableSchedules.length > 0 && (availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)).length > 0,
            flu1AppointmentScheduleList: availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    getAvailableSites(availableSchedules, includeSite) {
        let availableSites = [];
        for (var i = 0; i < availableSchedules.length; i++) {
            let siteName = availableSchedules[i].scheduleLocation.scheduleSite.siteName;
            let existingSites = availableSites.filter(as => as === siteName);
            if (existingSites.length === 0) {
                availableSites.push(siteName);
            }
        }
        if (includeSite) {
            if (availableSites.filter(as => as === includeSite).length === 0) {
                availableSites.push(includeSite);
            }
        }
        
        return availableSites;
    }

    getAvailableLocations(availableSchedules, siteName, includeLocation) {
        let availableLocations = [];
        let availableSchedulesForSite = availableSchedules.filter(as => as.scheduleLocation.scheduleSite.siteName === siteName);
        for (var i = 0; i < availableSchedulesForSite.length; i++) {
            let locationName = availableSchedulesForSite[i].scheduleLocation.locationName;
            let existingSites = availableLocations.filter(as => as === locationName);
            if (existingSites.length === 0) {
                availableLocations.push(locationName);
            }
        }
        if (includeLocation) {
            if (availableLocations.filter(al => al === includeLocation).length === 0) {
                availableLocations.push(includeLocation);
            }
        }
        
        return availableLocations;
    }

    getVaccineFromLocation(availableSchedules, siteName, locationName, scheduleDate, isFlu) {
        let availableSchedulesForSite = availableSchedules.filter(as => as.scheduleLocation.scheduleSite.siteName === siteName);
        for (var i = 0; i < availableSchedulesForSite.length; i++) {
            if (availableSchedulesForSite[i].scheduleLocation.locationName === locationName) {
                if (this.dateMatch(new Date(availableSchedulesForSite[i].scheduleDate), scheduleDate)) {
                    return isFlu ? availableSchedulesForSite[i].scheduleLocation.fluVaccine : availableSchedulesForSite[i].scheduleLocation.vaccine;
                }
            }
        }
        return '';
    }

    getVaccineFullName(vaccineCode) {
        const locatedVaccine = this.state.availableVaccineList.find(vl => vl.vaccineCode === vaccineCode);
        if (locatedVaccine) {
            return locatedVaccine.vaccineName;
        }
        return "Cannot determine vaccine";
    }

    getAppointmentType(availableSchedules, siteName, locationName, scheduleDate) {
        let availableSchedulesForSite = availableSchedules.filter(as => as.scheduleLocation.scheduleSite.siteName === siteName);
        for (var i = 0; i < availableSchedulesForSite.length; i++) {
            if (availableSchedulesForSite[i].scheduleLocation.locationName === locationName) {
                if (this.dateMatch(new Date(availableSchedulesForSite[i].scheduleDate), scheduleDate)) {
                    return availableSchedulesForSite[i].scheduleLocation.paediatricAppointments ?
                        (availableSchedulesForSite[i].scheduleLocation.fiveToElevenAppointments ? '511' : 'Paediatric') :
                        'Adult';
                }
            }
        }
        return 'Adult';
    }

    getAvailableSitesForFirstAppointment(callback) {
        const sites = this.getAvailableSites(this.state.firstAppointmentScheduleList);
        this.setState({
            firstAppointmentSiteList: sites
        }, function () {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableSitesForSecondAppointment(callback) {
        const sites = this.getAvailableSites(this.state.secondAppointmentScheduleList, this.state.secondappointmentsite);
        this.setState({
            secondAppointmentSiteList: sites
        }, function () {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableSitesForThirdAppointment(callback) {
        const sites = this.getAvailableSites(this.state.thirdAppointmentScheduleList);
        this.setState({
            thirdAppointmentSiteList: sites
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableSitesForBoosterAppointment(callback) {
        const sites = this.getAvailableSites(this.state.boost1AppointmentScheduleList);
        this.setState({
            boost1AppointmentSiteList: sites
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableSitesForFluAppointment(callback) {
        const sites = this.getAvailableSites(this.state.flu1AppointmentScheduleList);
        this.setState({
            flu1AppointmentSiteList: sites
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForFirstAppointment(callback) {
        const locations = this.getAvailableLocations(this.state.firstAppointmentScheduleList, this.state.firstappointmentsite);
        this.setState({
            firstAppointmentLocationList: locations
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForSecondAppointment(callback) {
        const locations = this.getAvailableLocations(this.state.secondAppointmentScheduleList, this.state.secondappointmentsite, this.state.secondappointmentlocation);
        this.setState({
            secondAppointmentLocationList: locations
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForThirdAppointment(callback) {
        const locations = this.getAvailableLocations(this.state.thirdAppointmentScheduleList, this.state.thirdappointmentsite, this.state.thirdappointmentlocation);
        this.setState({
            thirdAppointmentLocationList: locations
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForBoosterAppointment(callback) {
        const locations = this.getAvailableLocations(this.state.boost1AppointmentScheduleList, this.state.boost1appointmentsite, this.state.boost1appointmentlocation);
        this.setState({
            boost1AppointmentLocationList: locations
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForFluAppointment(callback) {
        const locations = this.getAvailableLocations(this.state.flu1AppointmentScheduleList, this.state.flu1appointmentsite, this.state.flu1appointmentlocation);
        this.setState({
            flu1AppointmentLocationList: locations
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    async getAvailableTimeSlots(scheduleDate, siteName, locationName, ignoreUtilisation) {

        const dateString = `${scheduleDate.getFullYear()}-${this.pad(scheduleDate.getMonth() + 1, 2)}-${this.pad(scheduleDate.getDate(), 2)}`;

        const response = await fetch('/ScheduleSlot?questionnaireKey=' + encodeURIComponent(this.state.questionnaire) + '&scheduleDate=' + encodeURIComponent(dateString) + '&siteName=' + encodeURIComponent(siteName) + '&locationName=' + encodeURIComponent(locationName) + '&ignoreUtilisation=' + (ignoreUtilisation ? 'true' : 'false'), {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else if (response.status === 404) {
            return [];
        } else {
            return [];
        }
    }

    async getAvailableTimeSlotsForFirstAppointment(callback) {
        if (this.state.firstappointmentdate) {
            let timeSlots = await this.getAvailableTimeSlots(this.state.firstappointmentdate, this.state.firstappointmentsite, this.state.firstappointmentlocation, true);
            if (this.state.firstappointmentdate > currentDate) {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
            }
            if (!this.dateMatch(this.state.firstappointmentdate, this.state.flu1appointmentdate) && this.state.firstappointmenttimeslot !== 'Add-on') {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
            }
            this.setState({
                firstAppointmentSlotList: timeSlots
            }, () => {
                if (callback) {
                    callback();
                }
            });
        } else {
            if (callback) {
                callback();
            }
        }
    }

    async getAvailableTimeSlotsForSecondAppointment(callback) {
        if (this.state.secondappointmentdate) {
            let timeSlots = await this.getAvailableTimeSlots(this.state.secondappointmentdate, this.state.secondappointmentsite, this.state.secondappointmentlocation, true);
            if (this.state.secondappointmentdate > currentDate) {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
            }
            if (!this.dateMatch(this.state.secondappointmentdate, this.state.flu1appointmentdate) && this.state.secondappointmenttimeslot !== 'Add-on') {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
            }
            this.setState({
                secondAppointmentSlotList: timeSlots
            }, () => {
                if (callback) {
                    callback();
                }
            });
        } else {
            if (callback) {
                callback();
            }
        }
    }

    async getAvailableTimeSlotsForThirdAppointment(callback) {
        if (this.state.thirdappointmentdate) {
            let timeSlots = await this.getAvailableTimeSlots(this.state.thirdappointmentdate, this.state.thirdappointmentsite, this.state.thirdappointmentlocation, false);
            if (this.state.thirdappointmentdate > currentDate) {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
            }
            if (!this.dateMatch(this.state.thirdappointmentdate, this.state.flu1appointmentdate) && this.state.thirdappointmenttimeslot !== 'Add-on') {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
            }
            this.setState({
                thirdAppointmentSlotList: timeSlots
            }, () => {
                if (callback) {
                    callback();
                }
            });
        } else {
            if (callback) {
                callback();
            }
        }
    }

    async getAvailableTimeSlotsForBoosterAppointment(callback) {
        if (this.state.boost1appointmentdate) {
            let timeSlots = await this.getAvailableTimeSlots(this.state.boost1appointmentdate, this.state.boost1appointmentsite, this.state.boost1appointmentlocation, false);
            if (this.state.boost1appointmentdate > currentDate) {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
            }
            if (!this.dateMatch(this.state.boost1appointmentdate, this.state.flu1appointmentdate) && this.state.boost1appointmenttimeslot !== 'Add-on') {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
            }
            this.setState({
                boost1AppointmentSlotList: timeSlots
            }, () => {
                if (callback) {
                    callback();
                }
            });
        } else {
            if (callback) {
                callback();
            }
        }
    }

    async getAvailableTimeSlotsForFluAppointment(callback) {
        if (this.state.flu1appointmentdate) {
            let timeSlots = await this.getAvailableTimeSlots(this.state.flu1appointmentdate, this.state.flu1appointmentsite, this.state.flu1appointmentlocation, false);
            if (this.state.flu1appointmentdate > currentDate) {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
            }
            if (!this.dateMatch(this.state.firstappointmentdate, this.state.flu1appointmentdate) &&
                !this.dateMatch(this.state.secondappointmentdate, this.state.flu1appointmentdate) &&
                !this.dateMatch(this.state.thirdappointmentdate, this.state.flu1appointmentdate) &&
                !this.dateMatch(this.state.boost1appointmentdate, this.state.flu1appointmentdate) &&
                this.state.flu1appointmenttimeslot !== 'Add-on') {
                timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
            }
            this.setState({
                flu1AppointmentSlotList: timeSlots
            }, () => {
                if (callback) {
                    callback();
                }
            });
        } else {
            if (callback) {
                callback();
            }
        }
    }

    syncFollowUpAppointmentSiteLocation() {
        if (!this.allowDateOverride && this.state.firstappointmentonly !== "Yes") {
            let nextUpdatedAnswers = [];
            if (this.state.firstappointmentsite) {
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentsite', this.state.firstappointmentsite);
            }
            if (this.state.firstappointmentlocation) {
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', this.state.firstappointmentlocation, nextUpdatedAnswers);
            }
            this.setState({
                secondappointmentsite: this.state.firstappointmentsite ? this.state.firstappointmentsite : this.state.secondappointmentsite,
                secondappointmentlocation: this.state.firstappointmentlocation ? this.state.firstappointmentlocation : this.state.secondappointmentlocation,
                answers: nextUpdatedAnswers
            }, () => {
                this.getAvailableLocationsForSecondAppointment();
            })
        }
    }

    secondAppointmentAdminQuestionsAvailable() {
        if (this.state.firstappointmentdate && !this.state.secondappointmentdate) {
            return false;
        }
        if (!this.state.firstappointmentdate && this.state.secondappointmentdate) {
            return true;
        }
        if (this.state.firstappointmentdate && this.state.secondappointmentdate && this.state.firstappointmentdate < currentDate) {
            return true;
        }
        return false;
    }

    thirdAppointmentAdminQuestionsAvailable() {
        if (this.state.thirdappointmentonly === "Yes") {
            if (this.state.thirdappointmentdate) {
                return true;
            } else {
                return false;
            }
        }
        if (this.state.firstappointmentdate && this.state.secondappointmentdate && !this.state.thirdappointmentdate) {
            return false;
        }
        if (!this.state.firstappointmentdate && !this.state.secondappointmentdate && this.state.thirdappointmentdate) {
            return true;
        }
        if (this.state.firstappointmentdate && this.state.secondappointmentdate && this.state.thirdappointmentdate && this.state.secondappointmentdate < currentDate) {
            return true;
        }
        if (this.state.firstappointmentdate && !this.state.secondappointmentdate && this.state.thirdappointmentdate && this.state.firstappointmentdate < currentDate) {
            return true;
        }
        if (!this.state.firstappointmentdate && this.state.secondappointmentdate && this.state.thirdappointmentdate && this.state.secondappointmentdate < currentDate) {
            return true;
        }
        return false;
    }

    boost1AppointmentAdminQuestionsAvailable() {
        if (this.state.boost1appointmentdate && this.state.boost1appointmentdate <= currentDate) {
            return true;
        }
        
        return false;
    }

    flu1AppointmentAdminQuestionsAvailable() {
        if (this.state.flu1appointmentdate && this.state.flu1appointmentdate <= currentDate) {
            return true;
        }

        return false;
    }

    showFirstAppointmentScreeningQuestions() {
        this.setState({
            showingFirstAppScreeningQuestions: true
        });
    }

    hideFirstAppointmentScreeningQuestions() {
        this.setState({
            showingFirstAppScreeningQuestions: false
        });
    }

    showFirstAppAdminFields() {
        this.setState({
            showingFirstAppAdminFields: true
        });
    }

    hideFirstAppAdminFields() {
        this.setState({
            showingFirstAppAdminFields: false
        });
    }

    showFollowUpAdminFields() {
        this.setState({
            showingSecondAppAdminFields: true
        });
    }

    hideFollowUpAdminFields() {
        this.setState({
            showingSecondAppAdminFields: false
        });
    }

    showThirdAppAdminFields() {
        this.setState({
            showingThirdAppAdminFields: true
        });
    }

    hideThirdAppAdminFields() {
        this.setState({
            showingThirdAppAdminFields: false
        });
    }

    toggleShowFirstPsd() {
        this.setState({
            showingFirstPsd: !this.state.showingFirstPsd
        });
    }

    toggleShowSecondPsd() {
        this.setState({
            showingSecondPsd: !this.state.showingSecondPsd
        });
    }

    toggleShowThirdPsd() {
        this.setState({
            showingThirdPsd: !this.state.showingThirdPsd
        });
    }

    toggleShowBoosterPsd() {
        this.setState({
            showingBoosterPsd: !this.state.showingBoosterPsd
        });
    }

    toggleShowFluPsd() {
        this.setState({
            showingFluPsd: !this.state.showingFluPsd
        });
    }

    renderAnswer(questions, questionKey) {
        const filteredQuestion = questions.filter(q => q.questionKey.toLowerCase() === questionKey.toLowerCase());
        const question = filteredQuestion.length > 0 ? filteredQuestion[0] : null;
        if (question === null || question.answers.length === 0) {
            return "Not Answered";
        } else {
            if (question.answers.length === 1) {
                return <strong>{question.answers[0]}</strong>;
            } else {
                return <ol>{question.answers.map((a, idx) => <li key={idx}><strong>{a}</strong></li>)}</ol>;
            }
        }
    }

    renderEligibleAnswer(questions) {
        const filteredQuestion = questions.filter(q => q.questionKey.toLowerCase() === "eligible");
        const question = filteredQuestion.length > 0 ? filteredQuestion[0] : null;
        if (question === null || question.answers.length === 0) {
            return "Not Answered";
        } else {
            const priorityList = [
                { key: 'CarehomeWorker', value: 'Carehome Worker' },
                { key: 'SocialCareWorker', value: 'Frontline Social Care Worker' },
                { key: 'HealthcareWorker', value: 'Frontline Healthcare Worker' },
                { key: '80', value: '80 years of age and over' },
                { key: '75', value: '75 years of age and over' },
                { key: '70', value: '70 years of age and over' },
                { key: 'ClinVulnerable', value: '18 to 69 years of age and clinically extremely vulnerable' },
                { key: '65', value: '65 years of age and over' },
                { key: '16To64Underlying', value: '16 to 64 years with underlying health conditions' },
                { key: '60', value: '60 years of age and over' },
                { key: '55', value: '55 years of age and over' },
                { key: '50', value: '50 years of age and over' },
                { key: '40', value: '40 years of age and over' },
                { key: '30', value: '30 years of age and over' },
                { key: '18', value: '18 years of age and over' },
                { key: '16CEV', value: '16 to 17 years of age and clinically extremely vulnerable' },
                { key: '16', value: '16 to 17 years of age' },
                { key: '12CEV', value: '12 to 15 years of age and clinically extremely vulnerable' },
                { key: '12', value: '12 to 15 years of age' },
                { key: '5CEV', value: '5 to 11 years of age and clinically extremely vulnerable' },
                { key: '5', value: '5 to 11 years of age' },
                { key: 'Carer', value: 'Carer of somebody in any of the above priority groups' }]
            const priorityItem = priorityList.find(pl => pl.key === question.answers[0]);
            if (priorityItem) {
                return <strong>{priorityItem.value}</strong>;
            } else {
                return <strong>{question.answers[0]} (IMPORTANT: Please click the change link to choose the correct group)</strong>;
            }
        }
    }

    getCareHomeOrganisation(questions, questionKey) {
        const filteredQuestion = questions.filter(q => q.questionKey.toLowerCase() === questionKey.toLowerCase());
        const question = filteredQuestion.length > 0 ? filteredQuestion[0] : null;
        if (question === null || question.answers.length === 0) {
            return "Not Answered";
        } else {
            const locatedOrg = this.state.carehomeOrganisationList.filter(o => o.id === question.answers[0]);
            if (locatedOrg.length > 0) {
                return <strong>{locatedOrg[0].name}</strong>;
            }
            if (question.answers.length === 1) {
                return <strong>{question.answers[0]}</strong>;
            } else {
                return <ol>{question.answers.map((a, idx) => <li key={idx}><strong>{a}</strong></li>)}</ol>;
            }
        }
    }

    getAnswersForStorage(questionKey, answer, answersList) {
        const answers = answersList || this.state.answers;
        const existingAnswer = answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        return updatedAnswers;
    }

    getSecondBookingWindowStart() {
        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
            if (this.isPaediatricCohort() || this.isFiveToElevenCohort()) {
                secondWindowBookingStart = 0;
            }
        }
        return secondWindowBookingStart;
    }

    editField(key, callback) {
        let itemsInEdit = this.state.inEdit.filter(k => k !== key);
        itemsInEdit.push(key);
        this.setState({
            inEdit: itemsInEdit
        }, function () {
            if (callback) {
                callback()
            }
        });
    }

    isEditing(key) {
        var isInEdit = this.state.inEdit.filter(k => k === key);
        if (isInEdit.length > 0) {
            return true;
        }
        return false;
    }

    getNewQuestions() {
        // determine which questions are mandatory based upon answers
        let screeningQuestionToggle = '';
        if (this.secondAppointmentAdminQuestionsAvailable()) {
            screeningQuestionToggle = '2';
        }
        let healthcareWorker = '';
        let carehomeWorker = '';
        let onBehalfOf = '';
        let willingToPartake = '';
        let infectionOrFeverish = '';
        let covidPositive4Weeks = '';
        let otherVaccinePast14Days = '';
        let fluJabPlanned = '';
        let seriousReaction = '';
        let seriousReactionConfirmed = '';
        let allergic = '';
        let allergicConfirmed = '';
        let bleedingDisorder = '';
        let bleedingDisorderConfirmed = '';
        let warfarin = '';
        let warfarinThresholdOk = '';
        let breastfeeding = '';
        let pregnant = '';
        let covidTrial = '';
        let covidTrialApproved = '';
        let organisation = '';
        let childBearingAge = '';
        var newQuestions = [];
        const healthcareWorkerAnswer = this.state.answers.filter(a => a.key === "healthcareworker");
        const carehomeWorkerAnswer = this.state.answers.filter(a => a.key === "carehomeworker");
        const onBehalfOfAnswer = this.state.answers.filter(a => a.key === "onbehalfof");
        const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
        const infectionOrFeverishAnswer = this.state.answers.filter(a => a.key === "infectionorfeverish" + screeningQuestionToggle);
        const covidPositive4WeeksAnswer = this.state.answers.filter(a => a.key === "covidpositive4weeks" + screeningQuestionToggle);
        const otherVaccinePast14DaysAnswer = this.state.answers.filter(a => a.key === "othervaccinepast14days" + screeningQuestionToggle);
        const fluJabPlannedAnswer = this.state.answers.filter(a => a.key === "flujabplanned" + screeningQuestionToggle);
        const seriousReactionAnswer = this.state.answers.filter(a => a.key === "seriousreaction" + screeningQuestionToggle);
        const seriousReactionConfirmedAnswer = this.state.answers.filter(a => a.key === "seriousreactionconfirmed" + screeningQuestionToggle);
        const allergicAnswer = this.state.answers.filter(a => a.key === "allergic" + screeningQuestionToggle);
        const allergicConfirmedAnswer = this.state.answers.filter(a => a.key === "allergicconfirmed" + screeningQuestionToggle);
        const bleedingDisorderAnswer = this.state.answers.filter(a => a.key === "bleedingdisorder" + screeningQuestionToggle);
        const bleedingDisorderConfirmedAnswer = this.state.answers.filter(a => a.key === "bleedingdisorderconfirmed" + screeningQuestionToggle);
        const warfarinAnswer = this.state.answers.filter(a => a.key === "warfarin" + screeningQuestionToggle);
        const warfarinThresholdOkAnswer = this.state.answers.filter(a => a.key === "warfarinthresholdok" + screeningQuestionToggle);
        const breastfeedingAnswer = this.state.answers.filter(a => a.key === "breastfeeding" + screeningQuestionToggle);
        const pregnantAnswer = this.state.answers.filter(a => a.key === "pregnant" + screeningQuestionToggle);
        const covidTrialAnswer = this.state.answers.filter(a => a.key === "covidtrial" + screeningQuestionToggle);
        const covidTrialApprovedAnswer = this.state.answers.filter(a => a.key === "covidtrialapproved" + screeningQuestionToggle);
        const organisationAnswer = this.state.answers.filter(a => a.key === "organisation");
        const childBearingAgeAnswer = this.state.answers.filter(a => a.key === "childbearingage");

        if (healthcareWorkerAnswer.length > 0) {
            healthcareWorker = healthcareWorkerAnswer[0].answer;
        }
        if (carehomeWorkerAnswer.length > 0) {
            carehomeWorker = carehomeWorkerAnswer[0].answer;
        }
        if (onBehalfOfAnswer.length > 0) {
            onBehalfOf = onBehalfOfAnswer[0].answer;
        }
        if (willingToPartakeAnswer.length > 0) {
            willingToPartake = willingToPartakeAnswer[0].answer;
        }
        if (infectionOrFeverishAnswer.length > 0) {
            infectionOrFeverish = infectionOrFeverishAnswer[0].answer;
        }
        if (covidPositive4WeeksAnswer.length > 0) {
            covidPositive4Weeks = covidPositive4WeeksAnswer[0].answer;
        }
        if (otherVaccinePast14DaysAnswer.length > 0) {
            otherVaccinePast14Days = otherVaccinePast14DaysAnswer[0].answer;
        }
        if (fluJabPlannedAnswer.length > 0) {
            fluJabPlanned = fluJabPlannedAnswer[0].answer;
        }
        if (seriousReactionAnswer.length > 0) {
            seriousReaction = seriousReactionAnswer[0].answer;
        }
        if (seriousReactionConfirmedAnswer.length > 0) {
            seriousReactionConfirmed = seriousReactionConfirmedAnswer[0].answer;
        }
        if (allergicAnswer.length > 0) {
            allergic = allergicAnswer[0].answer;
        }
        if (allergicConfirmedAnswer.length > 0) {
            allergicConfirmed = allergicConfirmedAnswer[0].answer;
        }
        if (bleedingDisorderAnswer.length > 0) {
            bleedingDisorder = bleedingDisorderAnswer[0].answer;
        }
        if (bleedingDisorderConfirmedAnswer.length > 0) {
            bleedingDisorderConfirmed = bleedingDisorderConfirmedAnswer[0].answer;
        }
        if (warfarinAnswer.length > 0) {
            warfarin = warfarinAnswer[0].answer;
        }
        if (warfarinThresholdOkAnswer.length > 0) {
            warfarinThresholdOk = warfarinThresholdOkAnswer[0].answer;
        }
        if (breastfeedingAnswer.length > 0) {
            breastfeeding = breastfeedingAnswer[0].answer;
        }
        if (pregnantAnswer.length > 0) {
            pregnant = pregnantAnswer[0].answer;
        }
        if (covidTrialAnswer.length > 0) {
            covidTrial = covidTrialAnswer[0].answer;
        }
        if (covidTrialApprovedAnswer.length > 0) {
            covidTrialApproved = covidTrialApprovedAnswer[0].answer;
        }
        if (organisationAnswer.length > 0) {
            organisation = organisationAnswer[0].answer;
        }
        if (childBearingAgeAnswer.length > 0) {
            childBearingAge = childBearingAgeAnswer[0].answer;
        }
        if (screeningQuestionToggle) {
            newQuestions = questionManager.getQuestions(healthcareWorker, carehomeWorker, onBehalfOf, willingToPartake, infectionOrFeverish, covidPositive4Weeks, otherVaccinePast14Days, fluJabPlanned, seriousReaction, seriousReactionConfirmed, allergic, allergicConfirmed, bleedingDisorder, bleedingDisorderConfirmed, warfarin, warfarinThresholdOk, breastfeeding, pregnant, covidTrial, covidTrialApproved, organisation, childBearingAge, false, true);
        }
        else {
            newQuestions = questionManager.getQuestions(healthcareWorker, carehomeWorker, onBehalfOf, willingToPartake, infectionOrFeverish, covidPositive4Weeks, otherVaccinePast14Days, fluJabPlanned, seriousReaction, seriousReactionConfirmed, allergic, allergicConfirmed, bleedingDisorder, bleedingDisorderConfirmed, warfarin, warfarinThresholdOk, breastfeeding, pregnant, covidTrial, covidTrialApproved, organisation, childBearingAge, true);
        }

        return newQuestions;
    }

    isMandatory(key) {

        const newQuestions = this.getNewQuestions();

        const locatedQuestion = newQuestions.filter(nq => nq.key === key);
        if (locatedQuestion.length > 0) {
            return locatedQuestion[0].mandatory;
        }
        return false;
    }

    showDeleteSubmission() {
        this.setState({
            showDeleteConfirm: true
        });
    }

    hideDeleteSubmission() {
        this.setState({
            showDeleteConfirm: false
        });
    }

    async deleteSubmission() {
        const { saveChanges, submission, returnCallback } = this.props;

        if (this.state.confirmedconsentforfirstapp === 'Yes'
            || this.state.prescribertimestamp || this.state.prescribertimestamp2 || this.state.prescribertimestamp3 || this.state.prescribertimestampboost1) {
            this.setState({
                submitting: false,
                errored: true,
                validationErrors: ['Cannot cancel/DNA submission as PSD information has been recorded for this record. Please contact IT Support if this record has been updated in error and needs removing.']
            });
        } else {
            const changes = {};
            this.state.answers.map(a => {
                const answerInSubmission = submission.questionAnswers.filter(qa => qa.questionKey === a.key);
                if (answerInSubmission.length > 0) {
                    let answer = '';
                    if (answerInSubmission[0].answers.length > 1) {
                        const combinedAnswer = answerInSubmission[0].answers.reduce((acc, cur, idx) => idx === 0 ? cur : acc + ", " + cur, '');
                        answer = combinedAnswer;
                    } else {
                        answer = answerInSubmission[0].answers[0];
                    }
                    if (answer !== a.answer) {
                        changes[a.key] = a.answer;
                    }
                } else {
                    if (a.key === 'confirmedconsentforfirstapp' && a.answer) {
                        changes['confirmedconsentforfirstappdate'] = new Date().toISOString().substr(0, 19).replace(/T/, ' ');
                    }
                    if (a.key === 'confirmedconsentforsecondapp' && a.answer) {
                        changes['confirmedconsentforsecondappdate'] = new Date().toISOString().substr(0, 19).replace(/T/, ' ');
                    }
                    changes[a.key] = a.answer;
                }
            });
            changes.deleted = true;
            changes.firstappointmentsite = '';
            changes.firstappointmentlocation = '';
            changes.firstappointmentdate = '';
            changes.firstappointmenttimeslot = '';
            changes.secondappointmentsite = '';
            changes.secondappointmentlocation = '';
            changes.secondappointmentdate = '';
            changes.secondappointmenttimeslot = '';

            await saveChanges(changes);
            if (returnCallback) {
                returnCallback();
            }
        }
    }

    async updateAdminFields(ignoreMandatory, callback) {
        const { submission, saveChanges } = this.props;

        let changes = {};

        this.state.answers.map(a => {
            const answerInSubmission = submission.questionAnswers.filter(qa => qa.questionKey === a.key);
            if (answerInSubmission.length > 0) {
                let answer = '';
                if (answerInSubmission[0].answers.length > 1) {
                    const combinedAnswer = answerInSubmission[0].answers.reduce((acc, cur, idx) => idx === 0 ? cur : acc + ", " + cur, '');
                    answer = combinedAnswer;
                } else {
                    answer = answerInSubmission[0].answers[0];
                }
                if (answer !== a.answer) {
                    changes[a.key] = a.answer;
                }
            } else {
                if (a.key === 'confirmedconsentforfirstapp' && a.answer) {
                    changes['confirmedconsentforfirstappdate'] = new Date().toISOString().substr(0, 19).replace(/T/, ' ');
                }
                if (a.key === 'confirmedconsentforsecondapp' && a.answer) {
                    changes['confirmedconsentforsecondappdate'] = new Date().toISOString().substr(0, 19).replace(/T/, ' ');
                }
                if (a.key === 'confirmedconsentforthirdapp' && a.answer) {
                    changes['confirmedconsentforthirdappdate'] = new Date().toISOString().substr(0, 19).replace(/T/, ' ');
                }
                changes[a.key] = a.answer;
            }
        });

        if (Object.keys(changes).length > 0) {
            this.setState({
                submitting: true,
                errored: false
            });

            let dateOfBirth = '';
            let dateOfBirthIsoFormat = '';
            let email = '';
            const dateOfBirthAnswer = this.state.answers.filter(a => a.key === 'dateofbirth');
            const emailAnswer = this.state.answers.filter(a => a.key === 'email');
            if (dateOfBirthAnswer.length > 0) {
                dateOfBirth = dateOfBirthAnswer[0].answer;
                if (dateOfBirth.length === 10) {
                    dateOfBirthIsoFormat = dateOfBirth.substring(6, 10) + "-" + dateOfBirth.substring(3, 5) + "-" + dateOfBirth.substring(0, 2);
                }
            }
            if (emailAnswer.length > 0) {
                email = emailAnswer[0].answer;
            }
            let appointmentsTooClose = true;
            let secondWindowBookingStart = defaultSecondBookingWindowStart;
            if (this.state.eligible) {
                const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
                if (newBookingWindow) {
                    secondWindowBookingStart = newBookingWindow.weeks;
                }
            }
            if (this.state.firstappointmentdate && this.state.secondappointmentdate) {
                let firstAppDate = new Date(this.state.firstappointmentdate.toDateString());
                firstAppDate.setDate(firstAppDate.getDate() + ((secondWindowBookingStart * 7) - 7));
                if (this.state.secondappointmentdate >= firstAppDate) {
                    appointmentsTooClose = false;
                }
            }
            else {
                appointmentsTooClose = false;
            }

            // determine which questions are mandatory based upon answers
            let screeningQuestionToggle = '';
            if (this.thirdAppointmentAdminQuestionsAvailable()) {
                screeningQuestionToggle = '3';
            }
            else if (this.secondAppointmentAdminQuestionsAvailable()) {
                screeningQuestionToggle = '2';
            }
            let healthcareWorker = '';
            let carehomeWorker = '';
            let onBehalfOf = '';
            let willingToPartake = '';
            let infectionOrFeverish = '';
            let covidPositive4Weeks = '';
            let otherVaccinePast14Days = '';
            let fluJabPlanned = '';
            let seriousReaction = '';
            let seriousReactionConfirmed = '';
            let allergic = '';
            let allergicConfirmed = '';
            let bleedingDisorder = '';
            let bleedingDisorderConfirmed = '';
            let warfarin = '';
            let warfarinThresholdOk = '';
            let breastfeeding = '';
            let pregnant = '';
            let covidTrial = '';
            let covidTrialApproved = '';
            let organisation = '';
            let childBearingAge = '';
            var newQuestions = [];
            const healthcareWorkerAnswer = this.state.answers.filter(a => a.key === "healthcareworker");
            const carehomeWorkerAnswer = this.state.answers.filter(a => a.key === "carehomeworker");
            const onBehalfOfAnswer = this.state.answers.filter(a => a.key === "onbehalfof");
            const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
            const infectionOrFeverishAnswer = this.state.answers.filter(a => a.key === "infectionorfeverish" + screeningQuestionToggle);
            const covidPositive4WeeksAnswer = this.state.answers.filter(a => a.key === "covidpositive4weeks" + screeningQuestionToggle);
            const otherVaccinePast14DaysAnswer = this.state.answers.filter(a => a.key === "othervaccinepast14days" + screeningQuestionToggle);
            const fluJabPlannedAnswer = this.state.answers.filter(a => a.key === "flujabplanned + screeningQuestionToggle");
            const seriousReactionAnswer = this.state.answers.filter(a => a.key === "seriousreaction" + screeningQuestionToggle);
            const seriousReactionConfirmedAnswer = this.state.answers.filter(a => a.key === "seriousreactionconfirmed" + screeningQuestionToggle);
            const allergicAnswer = this.state.answers.filter(a => a.key === "allergic" + screeningQuestionToggle);
            const allergicConfirmedAnswer = this.state.answers.filter(a => a.key === "allergicconfirmed" + screeningQuestionToggle);
            const bleedingDisorderAnswer = this.state.answers.filter(a => a.key === "bleedingdisorder" + screeningQuestionToggle);
            const bleedingDisorderConfirmedAnswer = this.state.answers.filter(a => a.key === "bleedingdisorderconfirmed" + screeningQuestionToggle);
            const warfarinAnswer = this.state.answers.filter(a => a.key === "warfarin" + screeningQuestionToggle);
            const warfarinThresholdOkAnswer = this.state.answers.filter(a => a.key === "warfarinthresholdok" + screeningQuestionToggle);
            const breastfeedingAnswer = this.state.answers.filter(a => a.key === "breastfeeding" + screeningQuestionToggle);
            const pregnantAnswer = this.state.answers.filter(a => a.key === "pregnant" + screeningQuestionToggle);
            const covidTrialAnswer = this.state.answers.filter(a => a.key === "covidtrial" + screeningQuestionToggle);
            const covidTrialApprovedAnswer = this.state.answers.filter(a => a.key === "covidtrialapproved" + screeningQuestionToggle);
            const organisationAnswer = this.state.answers.filter(a => a.key === "organisation");
            const childBearingAgeAnswer = this.state.answers.filter(a => a.key === "childbearingage");

            if (healthcareWorkerAnswer.length > 0) {
                healthcareWorker = healthcareWorkerAnswer[0].answer;
            }
            if (carehomeWorkerAnswer.length > 0) {
                carehomeWorker = carehomeWorkerAnswer[0].answer;
            }
            if (onBehalfOfAnswer.length > 0) {
                onBehalfOf = onBehalfOfAnswer[0].answer;
            }
            if (willingToPartakeAnswer.length > 0) {
                willingToPartake = willingToPartakeAnswer[0].answer;
            }
            if (infectionOrFeverishAnswer.length > 0) {
                infectionOrFeverish = infectionOrFeverishAnswer[0].answer;
            }
            if (covidPositive4WeeksAnswer.length > 0) {
                covidPositive4Weeks = covidPositive4WeeksAnswer[0].answer;
            }
            if (otherVaccinePast14DaysAnswer.length > 0) {
                otherVaccinePast14Days = otherVaccinePast14DaysAnswer[0].answer;
            }
            if (fluJabPlannedAnswer.length > 0) {
                fluJabPlanned = fluJabPlannedAnswer[0].answer;
            }
            if (seriousReactionAnswer.length > 0) {
                seriousReaction = seriousReactionAnswer[0].answer;
            }
            if (seriousReactionConfirmedAnswer.length > 0) {
                seriousReactionConfirmed = seriousReactionConfirmedAnswer[0].answer;
            }
            if (allergicAnswer.length > 0) {
                allergic = allergicAnswer[0].answer;
            }
            if (allergicConfirmedAnswer.length > 0) {
                allergicConfirmed = allergicConfirmedAnswer[0].answer;
            }
            if (bleedingDisorderAnswer.length > 0) {
                bleedingDisorder = bleedingDisorderAnswer[0].answer;
            }
            if (bleedingDisorderConfirmedAnswer.length > 0) {
                bleedingDisorderConfirmed = bleedingDisorderConfirmedAnswer[0].answer;
            }
            if (warfarinAnswer.length > 0) {
                warfarin = warfarinAnswer[0].answer;
            }
            if (warfarinThresholdOkAnswer.length > 0) {
                warfarinThresholdOk = warfarinThresholdOkAnswer[0].answer;
            }
            if (breastfeedingAnswer.length > 0) {
                breastfeeding = breastfeedingAnswer[0].answer;
            }
            if (pregnantAnswer.length > 0) {
                pregnant = pregnantAnswer[0].answer;
            }
            if (covidTrialAnswer.length > 0) {
                covidTrial = covidTrialAnswer[0].answer;
            }
            if (covidTrialApprovedAnswer.length > 0) {
                covidTrialApproved = covidTrialApprovedAnswer[0].answer;
            }
            if (organisationAnswer.length > 0) {
                organisation = organisationAnswer[0].answer;
            }
            if (childBearingAgeAnswer.length > 0) {
                childBearingAge = childBearingAgeAnswer[0].answer;
            }
            if (screeningQuestionToggle) {
                newQuestions = questionManager.getQuestions(healthcareWorker, carehomeWorker, onBehalfOf, willingToPartake, infectionOrFeverish, covidPositive4Weeks, otherVaccinePast14Days, fluJabPlanned, seriousReaction, seriousReactionConfirmed, allergic, allergicConfirmed, bleedingDisorder, bleedingDisorderConfirmed, warfarin, warfarinThresholdOk, breastfeeding, pregnant, covidTrial, covidTrialApproved, organisation, childBearingAge, false, true);
            } else {
                newQuestions = questionManager.getQuestions(healthcareWorker, carehomeWorker, onBehalfOf, willingToPartake, infectionOrFeverish, covidPositive4Weeks, otherVaccinePast14Days, fluJabPlanned, seriousReaction, seriousReactionConfirmed, allergic, allergicConfirmed, bleedingDisorder, bleedingDisorderConfirmed, warfarin, warfarinThresholdOk, breastfeeding, pregnant, covidTrial, covidTrialApproved, organisation, childBearingAge, true);
            }

            this.setState({
                questions: newQuestions
            }, async () => {
                let mandatoryQuestionsUnanswered = 0;
                const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
                for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
                    const mandatoryQuestion = mandatoryQuestions[mandatory];
                    const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
                    if (userAnswer.length === 0 || !userAnswer[0].answer) {
                        mandatoryQuestionsUnanswered++;
                    }
                }

                if (mandatoryQuestionsUnanswered > 0 && !ignoreMandatory) {
                    const validationErrors = ["Check questions with a red asterisk * that are unanswered."];
                    if (appointmentsTooClose) {
                        validationErrors.push("First appointment and follow-up appointment must be at least " + (secondWindowBookingStart - 1).toString() + " weeks apart!");
                    }
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: validationErrors
                    });
                }
                else if (this.state.nhsnumber && !this.isValidNhsNumber(this.state.nhsnumber)) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["NHS Number is not in the correct format! It should be a 10 digit number."]
                    });
                }
                else if (dateOfBirth !== '' && dateOfBirth.length < 10) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["Date of birth must be in the format DD/MM/YYYY!"]
                    });
                }
                else if (new Date(dateOfBirthIsoFormat) > currentDate) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["Date of birth cannot be in the future!"]
                    });
                }
                else if (email && !this.validateEmail(email)) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["Email address is not in a valid format!"]
                    });
                }
                else if (appointmentsTooClose && !ignoreMandatory) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["First appointment and follow-up appointment must be at least 11 weeks apart!"]
                    });
                }
                else {
                    if (changes.outcomeforfirstapp && changes.outcomeforfirstapp.toLowerCase().startsWith("cancelled")) {
                        changes.firstappointmentsite = '';
                        changes.firstappointmentlocation = '';
                        changes.firstappointmentdate = '';
                        changes.firstappointmenttimeslot = '';
                    }

                    if (changes.outcomeforsecondapp && changes.outcomeforsecondapp.toLowerCase().startsWith("cancelled")) {
                        changes.secondappointmentsite = '';
                        changes.secondappointmentlocation = '';
                        changes.secondappointmentdate = '';
                        changes.secondappointmenttimeslot = '';
                    }

                    if (changes.outcomeforthirdapp && changes.outcomeforthirdapp.toLowerCase().startsWith("cancelled")) {
                        changes.thirdappointmentsite = '';
                        changes.thirdappointmentlocation = '';
                        changes.thirdappointmentdate = '';
                        changes.thirdappointmenttimeslot = '';
                    }

                    if (changes.outcomeforboost1app && changes.outcomeforboost1app.toLowerCase().startsWith("cancelled")) {
                        changes.boost1appointmentsite = '';
                        changes.boost1appointmentlocation = '';
                        changes.boost1appointmentdate = '';
                        changes.boost1appointmenttimeslot = '';
                    }

                    if (changes.outcomeforflu1app && changes.outcomeforflu1app.toLowerCase().startsWith("cancelled")) {
                        changes.flu1appointmentsite = '';
                        changes.flu1appointmentlocation = '';
                        changes.flu1appointmentdate = '';
                        changes.flu1appointmenttimeslot = '';
                    }
                    await saveChanges(changes, (success) => {
                        this.setState({
                            isInvalid: false,
                            submitting: false,
                            validationErrors: []
                        }, () => {
                            if (callback) {
                                callback(success);
                            }
                        });
                    });                    
                }
            });
        } else {
            await saveChanges({}, (success) => {
                this.setState({
                    isInvalid: false,
                    submitting: false,
                    validationErrors: []
                }, () => {
                    if (callback) {
                        callback(success);
                    }
                });
            });
            
        }
    }

    rawChangeHandler(key, value, cb) {
        const updatedAnswers = this.getAnswersForStorage(key, value);
        this.setState({
            [key]: value,
            answers: updatedAnswers,
            isInvalid: false
        }, () => { if (cb) { cb() } });
    }

    textChangeHandler(e, q) {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const updatedAnswers = this.getAnswersForStorage(q, e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
            answers: updatedAnswers,
            isInvalid: false
        }, async function () {
            if (targetName === 'firstappointmentsite' && targetValue) {
                this.getAvailableLocationsForFirstAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentlocation', '')
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                if (!this.state.allowDateOverride && this.state.firstappointmentonly !== 'Yes') {
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentsite', targetValue, nextUpdatedAnswers)
                }

                this.setState({
                    firstappointmentlocation: '',
                    firstappointmentdate: '',
                    firstappointmenttimeslot: '',
                    secondappointmentsite: (!this.state.allowDateOverride && this.state.firstappointmentonly !== 'Yes') ? targetValue : this.state.secondappointmentsite,
                    vaccinetype: '',
                    answers: nextUpdatedAnswers
                }, () => {
                    this.getAvailableLocationsForSecondAppointment();
                });
            }
            if (targetName === 'firstappointmenttimeslot' && targetValue) {
                this.getSchedulesForFollowUpAppointment(() => this.getAvailableLocationsForSecondAppointment());
            }
            if (targetName === 'firstappointmentlocation' && targetValue) {
                let nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '')
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                if (!this.state.allowDateOverride && this.state.firstappointmentonly !== 'Yes') {
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', targetValue, nextUpdatedAnswers)
                }

                this.setState({
                    firstappointmentdate: '',
                    firstappointmenttimeslot: '',
                    secondappointmentlocation: (!this.state.allowDateOverride && this.state.firstappointmentonly !== 'Yes') ? targetValue : this.state.secondappointmentlocation,
                    vaccinetype: '',
                    vaccinetype2: '',
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'secondappointmentsite' && targetValue) {
                let nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    secondappointmentlocation: '',
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype2: '',
                    answers: nextUpdatedAnswers
                }, () => {
                    this.getAvailableLocationsForSecondAppointment();
                });
            }
            if (targetName === 'secondappointmentlocation' && targetValue) {
                let nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype2: '',
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'thirdappointmentsite' && targetValue) {
                this.getAvailableLocationsForThirdAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype3', '', nextUpdatedAnswers)
                this.setState({
                    thirdappointmentlocation: '',
                    thirdappointmentdate: '',
                    thirdappointmenttimeslot: '',
                    vaccinetype3: '',
                    anyThirdAppsAvailable: true,
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'boost1appointmentsite' && targetValue) {
                this.getAvailableLocationsForBoosterAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetypeboost1', '', nextUpdatedAnswers)
                this.setState({
                    boost1appointmentlocation: '',
                    boost1appointmentdate: '',
                    boost1appointmenttimeslot: '',
                    vaccinetypeboost1: '',
                    anyBoosterAppsAvailable: true,
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'flu1appointmentsite' && targetValue) {
                this.getAvailableLocationsForFluAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetypeflu1', '', nextUpdatedAnswers)
                this.setState({
                    flu1appointmentlocation: '',
                    flu1appointmentdate: '',
                    flu1appointmenttimeslot: '',
                    vaccinetypeflu1: '',
                    anyFluAppsAvailable: true,
                    answers: nextUpdatedAnswers
                });
            }
        });
    }

    textChangeNoStoreHandler(e, q) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeRawHandler(e, q) {
        const updatedAnswers = this.getAnswersForStorage(q, e.target.rawValue);
        this.setState({
            [e.target.name]: e.target.rawValue,
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    textChangeNoStoreRawHandler(e) {
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    radioChangeHandler(e, q) {
        if (e.target.checked) {
            const newValue = e.target.value;
            const newStateValues = {};
            let updatedAnswers = this.getAnswersForStorage(q, newValue);
            if (q === 'eligible') {
                if (newValue === 'CarehomeWorker' || newValue === 'SocialCareWorker' || newValue === 'HealthcareWorker') {
                    updatedAnswers = this.getAnswersForStorage('healthcareworker', 'Yes', updatedAnswers);
                    newStateValues.healthcareworker = 'Yes';
                } else {
                    updatedAnswers = this.getAnswersForStorage('healthcareworker', 'No', updatedAnswers);
                    updatedAnswers = this.getAnswersForStorage('carehomeorganisation', '', updatedAnswers);
                    newStateValues.healthcareworker = 'No';
                    newStateValues.carehomeorganisation = '';
                }
                if (newValue === 'CarehomeWorker') {
                    updatedAnswers = this.getAnswersForStorage('carehomeworker', 'Yes', updatedAnswers);
                    newStateValues.carehomeworker = 'Yes';
                } else {
                    updatedAnswers = this.getAnswersForStorage('carehomeworker', 'No', updatedAnswers);
                    updatedAnswers = this.getAnswersForStorage('carehomeorganisation', '', updatedAnswers);
                    newStateValues.carehomeworker = 'No';
                    newStateValues.carehomeorganisation = '';
                }
            }
            this.setState({
                [e.target.name]: newValue,
                ...newStateValues,
                answers: updatedAnswers,
                isInvalid: false
            }, () => {
                if (q === 'restrictedmobility') {
                    // refresh schedules
                    this.getSchedulesForFirstAppointment();
                }
                if (q === 'seriousreaction') {
                    if (newValue === 'Yes') {
                        this.setState({
                            allowedVaccineType: 'Az'
                        }, () => {
                            // refresh schedules
                            this.getSchedulesForFirstAppointment();
                        });
                    } else {
                        this.setState({
                            allowedVaccineType: ''
                        }, () => {
                            // refresh schedules
                            this.getSchedulesForFirstAppointment();
                        });
                    }
                }
                if (q === 'eligible') {
                    if (newValue === 'SocialCareWorker' || newValue === 'HealthcareWorker') {
                        this.editField("healthcareworker");
                    }
                    if (newValue === 'CarehomeWorker') {
                        this.editField("healthcareworker", () => { this.editField("carehomeworker"); });
                    }
                }
            })
        }
    }

    checkedChangeHandler(e, q) {
        let newValue = '';
        if (e.target.checked) {
            newValue = e.target.value;
        }

        const updatedAnswers = this.getAnswersForStorage(q, newValue);

        this.setState({
            [e.target.name]: newValue,
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    checkedChangeNoStoreHandler(e, q) {
        let newValue = '';
        if (e.target.checked) {
            newValue = e.target.value;
        }
        this.setState({
            [e.target.name]: newValue
        }, () => {
            if (q === 'firstappointmentdateoverride') {
                this.getSchedulesForFirstAppointment();
            }
            if (q === 'secondappointmentdateoverride') {
                this.getSchedulesForFollowUpAppointment();
            }
            if (q === 'thirdappointmentdateoverride') {
                this.getSchedulesForThirdAppointment();
            }
            if (q === 'boost1appointmentdateoverride') {
                this.getSchedulesForBoosterAppointment();
            }
            if (q === 'flu1appointmentdateoverride') {
                this.getSchedulesForFluAppointment();
            }
        });
    }

    dateOfOtherVaccineChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        const updatedAnswers = this.getAnswersForStorage('othervaccinedate', newValue);

        this.setState({
            othervaccinedate: datePicked ? datePicked : '',
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    dateOfOtherVaccine2ChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        const updatedAnswers = this.getAnswersForStorage('othervaccinedate2', newValue);

        this.setState({
            othervaccinedate2: datePicked ? datePicked : '',
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    dateOfPlannedFluJabChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        const updatedAnswers = this.getAnswersForStorage('flujabplanneddate', newValue);

        this.setState({
            flujabplanneddate: datePicked ? datePicked : '',
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    dateOfFirstVaccinationChangeHandler(datePicked) {
        let newValue = '';
        let defaultSecondVaccinationDate = '';

        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
            defaultSecondVaccinationDate = new Date(datePicked);
            defaultSecondVaccinationDate.setDate(defaultSecondVaccinationDate.getDate() + ((secondWindowBookingStart * 7) - 7));
        }

        let updatedAnswers = this.getAnswersForStorage('firstappointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', updatedAnswers);

        this.setState({
            firstappointmentdate: datePicked ? datePicked : '',
            defaultSecondVaccinationDate: defaultSecondVaccinationDate,
            firstappointmenttimeslot: '',
            answers: updatedAnswers,
            isInvalid: false
        }, async function () {
            const firstAppSchedule = await this.getCurrentSchedule(datePicked, this.state.firstappointmentsite, this.state.firstappointmentlocation);
            const vaccine = firstAppSchedule ? (this.getVaccineFromLocation([firstAppSchedule], this.state.firstappointmentsite, this.state.firstappointmentlocation, datePicked) || '') : '';
            let updatedVaccineAnswers = this.getAnswersForStorage('vaccinetype', vaccine);

            this.setState({
                vaccinetype: vaccine,
                answers: updatedVaccineAnswers
            }, () => {
                this.getAvailableTimeSlotsForFirstAppointment(() => {
                    this.getSchedulesForFollowUpAppointment();
                });
            })
        });
    }

    dateOfSecondVaccinationChangeHandler(datePicked) {
        let newValue = '';
        let defaultThirdVaccinationDate = '';
        let thirdWindowBookingStart = defaultThirdBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                thirdWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        let updatedAnswers = this.getAnswersForStorage('secondappointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', updatedAnswers);

        this.setState({
            secondappointmentdate: datePicked ? datePicked : '',
            secondappointmenttimeslot: '',
            defaultThirdVaccinationDate: defaultThirdVaccinationDate,
            answers: updatedAnswers,
            isInvalid: false
        }, async function () {
            const secondAppSchedule = await this.getCurrentSchedule(datePicked, this.state.secondappointmentsite, this.state.secondappointmentlocation);
            const vaccine = secondAppSchedule ? (this.getVaccineFromLocation([secondAppSchedule], this.state.secondappointmentsite, this.state.secondappointmentlocation, datePicked) || '') : '';
            let updatedVaccineAnswers = this.getAnswersForStorage('vaccinetype2', vaccine);
            this.setState({
                vaccinetype2: vaccine,
                answers: updatedVaccineAnswers
            }, () => {
                this.getAvailableTimeSlotsForSecondAppointment();
            })
        });
    }

    dateOfThirdVaccinationChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        let updatedAnswers = this.getAnswersForStorage('thirdappointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('thirdappointmenttimeslot', '', updatedAnswers);

        this.setState({
            thirdappointmentdate: datePicked ? datePicked : '',
            thirdappointmenttimeslot: '',
            answers: updatedAnswers,
            isInvalid: false
        }, async function () {
            const thirdAppSchedule = await this.getCurrentSchedule(datePicked, this.state.thirdappointmentsite, this.state.thirdappointmentlocation);
            const vaccine = thirdAppSchedule ? (this.getVaccineFromLocation([thirdAppSchedule], this.state.thirdappointmentsite, this.state.thirdappointmentlocation, datePicked) || '') : '';
            let updatedVaccineAnswers = this.getAnswersForStorage('vaccinetype3', vaccine);
            this.setState({
                vaccinetype3: vaccine,
                answers: updatedVaccineAnswers
            }, () => {
                this.getAvailableTimeSlotsForThirdAppointment();
            });
        });
    }

    dateOfBoosterVaccinationChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        let updatedAnswers = this.getAnswersForStorage('boost1appointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('boost1appointmenttimeslot', '', updatedAnswers);

        this.setState({
            boost1appointmentdate: datePicked ? datePicked : '',
            boost1appointmenttimeslot: '',
            answers: updatedAnswers,
            isInvalid: false
        }, async function () {
            const boosterAppSchedule = await this.getCurrentSchedule(datePicked, this.state.boost1appointmentsite, this.state.boost1appointmentlocation);
            const vaccine = boosterAppSchedule ? (this.getVaccineFromLocation([boosterAppSchedule], this.state.boost1appointmentsite, this.state.boost1appointmentlocation, datePicked) || '') : '';
            let updatedVaccineAnswers = this.getAnswersForStorage('vaccinetypeboost1', vaccine);
            this.setState({
                vaccinetypeboost1: vaccine,
                answers: updatedVaccineAnswers
            }, () => {
                this.getAvailableTimeSlotsForBoosterAppointment();
            });
        });
    }

    dateOfFluVaccinationChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        let updatedAnswers = this.getAnswersForStorage('flu1appointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('flu1appointmenttimeslot', '', updatedAnswers);

        this.setState({
            flu1appointmentdate: datePicked ? datePicked : '',
            flu1appointmenttimeslot: '',
            answers: updatedAnswers,
            isInvalid: false
        }, async function () {
            const fluAppSchedule = await this.getCurrentSchedule(datePicked, this.state.flu1appointmentsite, this.state.flu1appointmentlocation);
            const vaccine = fluAppSchedule ? (this.getVaccineFromLocation([fluAppSchedule], this.state.flu1appointmentsite, this.state.flu1appointmentlocation, datePicked, true) || '') : '';
            let updatedVaccineAnswers = this.getAnswersForStorage('vaccinetypeflu1', vaccine);
            this.setState({
                vaccinetypeflu1: vaccine,
                answers: updatedVaccineAnswers
            }, () => {
                this.getAvailableTimeSlotsForFluAppointment();
            });
        });
    }

    viewPersonDetails() {
        this.personalDetailsRef.scrollIntoView();
    }

    isValidNhsNumber(nhsNumber) {

        var isValid = false;

        if (nhsNumber.length == 10) {

            var total = 0;

            var i = 0;
            for (i = 0; i <= 8; i++) {
                var digit = nhsNumber.substr(i, 1);
                var factor = 10 - i;
                total += (digit * factor);
            }

            var checkDigit = (11 - (total % 11));

            if (checkDigit == 11) { checkDigit = 0; }

            if (checkDigit == nhsNumber.substr(9, 1)) { isValid = true; }
        }

        return isValid;
    }

    isPaediatricCohort() {
        return (this.state.eligible === "12CEV" || this.state.eligible === "12") || this.isFiveToElevenCohort();
    }

    isFiveToElevenCohort() {
        return (this.state.eligible === "5CEV" || this.state.eligible === "5");
    }

    isOnlyEligibleForSingleAppointment() {
        return (this.state.eligible === "12" || this.state.eligible === "16");
    }

    showIf(answerConfig) {
        var keys = Object.keys(answerConfig);
        let show = true;
        let currentAnswer = '';
        for (var keyIter = 0; keyIter < keys.length; keyIter++) {
            currentAnswer = '';
            if (keys[keyIter].endsWith('Answered')) {
                const answerVal = answerConfig[keys[keyIter].replace('Answered', '')] || '';
                const answer = this.state.answers.filter(a => a.key === keys[keyIter].replace('Answered', '').toLowerCase());
                if (answer.length > 0) {
                    currentAnswer = answer[0].answer;
                }
                if (answerVal !== '') {
                    if (Array.isArray(answerVal)) {
                        if (!answerVal.find(av => av === currentAnswer)) {
                            show = false;
                        }
                    } else {
                        if (currentAnswer !== answerVal) {
                            show = false;
                        }
                    }
                } else {
                    if (currentAnswer === '') {
                        show = false;
                    }
                }
            }
        }
        return show;
    }

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    renderPsd(doseName, readonly, showingAsAdditional, multiplePsds) {
        let careHomeOrg = '';
        const secondDose = doseName === "second";
        const thirdDose = doseName === "third";
        const boosterDose = doseName === "boost1";
        const fluDose = doseName === "flu1";
        
        //const isHealthcareSocialOrCareHomeWorker = this.state.healthcareworker === "Yes" || this.state.carehomeworker === "Yes" || this.state.eligible === "SocialCareWorker";

        //const worksInResidentialCareHome = this.state.carehomeworker === "Yes" && (this.state.carehomeorganisation ? true : false);

        const isCarerVal = !this.state.carer ? (this.state.eligible === "Carer" ? "Yes" : "No") : this.state.carer;
        const locatedOrg = this.state.carehomeOrganisationList.filter(o => o.id === this.state.carehomeorganisation);
        if (locatedOrg.length > 0) {
            careHomeOrg = locatedOrg[0].name;
        }
        const organisation = this.state.carehomeworker === "Yes" ? careHomeOrg : this.state.organisation;


        if (boosterDose) {
            return <CovidVaccinationPSDPrint role={this.state.role} disallowAdministering={readonly} vaccineType={this.state.vaccinetypeboost1} appointmentType={this.state.boost1appointmenttype} showingAsAdditionalPsd={showingAsAdditional} multiplePsds={multiplePsds} changeHandler={this.rawChangeHandler} loggedInUserName={this.state.username} patientFirstName={this.state.forename} patientLastName={this.state.lastname} patientDateofBirth={this.state.dateofbirth} patientNHSNumber={this.state.nhsnumber} patientHomeAddress={this.state.address} patientPostCode={this.state.postcode} gender={this.state.gender} ethnicity={this.state.ethnicity} carer={isCarerVal} isHealthcareWorker={this.state.healthcareworker} worksInCareHome={this.state.carehomeworker} organisation={organisation} staffRole={this.state.jobrole} PSDFirstDose="No" PSDSecondDose="No" PSDThirdDose="No" PSDBoosterDose="Yes" PSDFluDose="No" BatchNo="" VaccineExpiry="" CurrentDate={currentDate}
                seriousreaction={this.state.seriousreactionboost1} allergic={this.state.allergicboost1 === "Yes" ? (this.state.allergicconfirmedboost1 === "Yes" ? "No" : "Yes") : this.state.allergicboost1} infectionorfeverish={this.state.infectionorfeverishboost1} bleedingdisorder={this.state.bleedingdisorderboost1 === "Yes" ? (this.state.bleedingdisorderconfirmedboost1 === "Yes" ? "No" : "Yes") : this.state.bleedingdisorderboost1} pregnant={this.state.pregnantboost1} breastfeeding={this.state.breastfeedingboost1} covidtrial={this.state.covidtrialboost1 === "Yes" ? (this.state.covidtrialapprovedboost1 === "Yes" ? "No" : "Yes") : this.state.covidtrialboost1} flujabpast7days={this.state.flujabpast7daysboost1} covidpositive4weeks={this.state.covidpositive4weeksboost1} covidpositive12weeks={this.state.covidpositive12weeksboost1} bcgpast3months={this.state.bcgpast3monthsboost1}
                assessorName={this.state.assessornameboost1} assessorAdAccount={this.state.assessoradaccountboost1} assessorTimestamp={this.state.assessortimestampboost1} assessorComments={this.state.assessorcommentsboost1}
                prescriberName={this.state.prescribernameboost1} prescriberAdAccount={this.state.prescriberadaccountboost1} prescriberTimestamp={this.state.prescribertimestampboost1} prescriberComments={this.state.prescribercommentsboost1} prescriberRegistrationNumber={this.state.prescriberregistrationnumberboost1}
                vaccinatorName={this.state.vaccinatornameboost1} vaccinatorAdAccount={this.state.vaccinatoradaccountboost1} vaccinatorTimestamp={this.state.vaccinatortimestampboost1} vaccinatorComments={this.state.vaccinatorcommentsboost1}
                batchNumber={this.state.batchnumberboost1} batchExpiry={this.state.batchexpiryboost1} vaccinationSite={this.state.vaccinationsiteboost1} doseNumber={this.state.dosenumberboost1} dtcReference={this.state.dtcreferenceboost1} covidSymptoms={this.state.covidsymptomsboost1} confirmVaxMatch={this.state.confirmvaxmatchboost1}
                residesInCarehome={this.state.carehomeresident} cannotLocateOnNivs={this.state.cannotlocateonnivsboost1} confirmStaffCheck={this.state.confirmstaffcheckboost1} confirmFluVaxCheck={this.state.confirmfluvaxcheckboost1}
                updateAdminFields={this.updateAdminFields} submitting={this.state.submitting} saveCallback={this.props.returnCallback} finishedVaccinationCallback={this.props.finishedVaccinationCallback} cancelScreeningCallback={this.props.cancelScreeningCallback} cancelPrescribingCallback={this.props.cancelPrescribingCallback} cancelAdministeringCallback={this.props.cancelAdministeringCallback}
                parentConsentForVaccine={this.state.parentconsentforvaccineboost1}
                parentMoreInformationNeededBeforeConsent={this.state.parentmoreinformationneededbeforeconsentboost1}
                guardianFullName={this.state.guardianfullnameboost1}
                guardianRelationshipToRecipient={this.state.guardianrelationshiptorecipientboost1}
                patientConsentForVaccine={this.state.patientconsentforvaccineboost1}
                moreInformationNeededBeforeConsent={this.state.moreinformationneededbeforeconsentboost1}
                satisfiedWithConsent={this.state.satisfiedwithconsentboost1}
                outcomeAfterConsentDiscussion={this.state.outcomeafterconsentdiscussionboost1}
                thirtyandfirstdose={this.state.thirtyandfirstdoseboost1}
                lowplateletcountafterfirst={this.state.lowplateletcountafterfirstboost1}
                historyofthrombosis={this.state.historyofthrombosisboost1}
                historyofbleedingdisorder={this.state.historyofbleedingdisorderboost1}
                underageandfirstdose={this.state.underageandfirstdoseboost1}
                capillaryleaksyndrome={this.state.capillaryleaksyndromeboost1}
            />;
        } else if (thirdDose) {
            return <CovidVaccinationPSDPrint role={this.state.role} disallowAdministering={readonly} vaccineType={this.state.vaccinetype3} appointmentType={this.state.thirdappointmenttype} showingAsAdditionalPsd={showingAsAdditional} multiplePsds={multiplePsds} changeHandler={this.rawChangeHandler} loggedInUserName={this.state.username} patientFirstName={this.state.forename} patientLastName={this.state.lastname} patientDateofBirth={this.state.dateofbirth} patientNHSNumber={this.state.nhsnumber} patientHomeAddress={this.state.address} patientPostCode={this.state.postcode} gender={this.state.gender} ethnicity={this.state.ethnicity} carer={isCarerVal} isHealthcareWorker={this.state.healthcareworker} worksInCareHome={this.state.carehomeworker} organisation={organisation} staffRole={this.state.jobrole} PSDFirstDose="No" PSDSecondDose="No" PSDThirdDose="Yes" PSDBoosterDose="No" PSDFluDose="No" BatchNo="" VaccineExpiry="" CurrentDate={currentDate}
                seriousreaction={this.state.seriousreaction3} allergic={this.state.allergic3 === "Yes" ? (this.state.allergicconfirmed3 === "Yes" ? "No" : "Yes") : this.state.allergic3} infectionorfeverish={this.state.infectionorfeverish3} bleedingdisorder={this.state.bleedingdisorder3 === "Yes" ? (this.state.bleedingdisorderconfirmed3 === "Yes" ? "No" : "Yes") : this.state.bleedingdisorder3} pregnant={this.state.pregnant3} breastfeeding={this.state.breastfeeding3} covidtrial={this.state.covidtrial3 === "Yes" ? (this.state.covidtrialapproved3 === "Yes" ? "No" : "Yes") : this.state.covidtrial3} flujabpast7days={this.state.flujabpast7days3} covidpositive4weeks={this.state.covidpositive4weeks3} covidpositive12weeks={this.state.covidpositive12weeks3} bcgpast3months={this.state.bcgpast3months3}
                assessorName={this.state.assessorname3} assessorAdAccount={this.state.assessoradaccount3} assessorTimestamp={this.state.assessortimestamp3} assessorComments={this.state.assessorcomments3}
                prescriberName={this.state.prescribername3} prescriberAdAccount={this.state.prescriberadaccount3} prescriberTimestamp={this.state.prescribertimestamp3} prescriberComments={this.state.prescribercomments3} prescriberRegistrationNumber={this.state.prescriberregistrationnumber3}
                vaccinatorName={this.state.vaccinatorname3} vaccinatorAdAccount={this.state.vaccinatoradaccount3} vaccinatorTimestamp={this.state.vaccinatortimestamp3} vaccinatorComments={this.state.vaccinatorcomments3}
                batchNumber={this.state.batchnumber3} batchExpiry={this.state.batchexpiry3} vaccinationSite={this.state.vaccinationsite3} doseNumber={this.state.dosenumber3} dtcReference={this.state.dtcreference3} covidSymptoms={this.state.covidsymptoms3} confirmVaxMatch={this.state.confirmvaxmatch3}
                residesInCarehome={this.state.carehomeresident} cannotLocateOnNivs={this.state.cannotlocateonnivs3} confirmStaffCheck={this.state.confirmstaffcheck3} confirmFluVaxCheck={this.state.confirmfluvaxcheck3}
                updateAdminFields={this.updateAdminFields} submitting={this.state.submitting} saveCallback={this.props.returnCallback} finishedVaccinationCallback={this.props.finishedVaccinationCallback} cancelScreeningCallback={this.props.cancelScreeningCallback} cancelPrescribingCallback={this.props.cancelPrescribingCallback} cancelAdministeringCallback={this.props.cancelAdministeringCallback}
                parentConsentForVaccine={this.state.parentconsentforvaccine3}
                parentMoreInformationNeededBeforeConsent={this.state.parentmoreinformationneededbeforeconsent3}
                guardianFullName={this.state.guardianfullname3}
                guardianRelationshipToRecipient={this.state.guardianrelationshiptorecipient3}
                patientConsentForVaccine={this.state.patientconsentforvaccine3}
                moreInformationNeededBeforeConsent={this.state.moreinformationneededbeforeconsent3}
                satisfiedWithConsent={this.state.satisfiedwithconsent3}
                outcomeAfterConsentDiscussion={this.state.outcomeafterconsentdiscussion3}
                thirtyandfirstdose={this.state.thirtyandfirstdose3}
                lowplateletcountafterfirst={this.state.lowplateletcountafterfirst3}
                historyofthrombosis={this.state.historyofthrombosis3}
                historyofbleedingdisorder={this.state.historyofbleedingdisorder3}
                underageandfirstdose={this.state.underageandfirstdose3}
                capillaryleaksyndrome={this.state.capillaryleaksyndrome3}
            />;
        } else if (secondDose) {
            return <CovidVaccinationPSDPrint role={this.state.role} disallowAdministering={readonly} vaccineType={this.state.vaccinetype2} appointmentType={this.state.secondappointmenttype} showingAsAdditionalPsd={showingAsAdditional} multiplePsds={multiplePsds} changeHandler={this.rawChangeHandler} loggedInUserName={this.state.username} patientFirstName={this.state.forename} patientLastName={this.state.lastname} patientDateofBirth={this.state.dateofbirth} patientNHSNumber={this.state.nhsnumber} patientHomeAddress={this.state.address} patientPostCode={this.state.postcode} gender={this.state.gender} ethnicity={this.state.ethnicity} carer={isCarerVal} isHealthcareWorker={this.state.healthcareworker} worksInCareHome={this.state.carehomeworker} organisation={organisation} staffRole={this.state.jobrole} PSDFirstDose="No" PSDSecondDose="Yes" PSDThirdDose="No" PSDBoosterDose="No" PSDFluDose="No" BatchNo="" VaccineExpiry="" CurrentDate={currentDate}
                seriousreaction={this.state.seriousreaction2} allergic={this.state.allergic2 === "Yes" ? (this.state.allergicconfirmed2 === "Yes" ? "No" : "Yes") : this.state.allergic2} infectionorfeverish={this.state.infectionorfeverish2} bleedingdisorder={this.state.bleedingdisorder2 === "Yes" ? (this.state.bleedingdisorderconfirmed2 === "Yes" ? "No" : "Yes") : this.state.bleedingdisorder2} pregnant={this.state.pregnant2} breastfeeding={this.state.breastfeeding2} covidtrial={this.state.covidtrial2 === "Yes" ? (this.state.covidtrialapproved2 === "Yes" ? "No" : "Yes") : this.state.covidtrial2} flujabpast7days={this.state.flujabpast7days2} covidpositive4weeks={this.state.covidpositive4weeks2} covidpositive12weeks={this.state.covidpositive12weeks2} bcgpast3months={this.state.bcgpast3months2}
                assessorName={this.state.assessorname2} assessorAdAccount={this.state.assessoradaccount2} assessorTimestamp={this.state.assessortimestamp2} assessorComments={this.state.assessorcomments2}
                prescriberName={this.state.prescribername2} prescriberAdAccount={this.state.prescriberadaccount2} prescriberTimestamp={this.state.prescribertimestamp2} prescriberComments={this.state.prescribercomments2} prescriberRegistrationNumber={this.state.prescriberregistrationnumber2}
                vaccinatorName={this.state.vaccinatorname2} vaccinatorAdAccount={this.state.vaccinatoradaccount2} vaccinatorTimestamp={this.state.vaccinatortimestamp2} vaccinatorComments={this.state.vaccinatorcomments2}
                batchNumber={this.state.batchnumber2} batchExpiry={this.state.batchexpiry2} vaccinationSite={this.state.vaccinationsite2} doseNumber={this.state.dosenumber2} dtcReference={this.state.dtcreference2} covidSymptoms={this.state.covidsymptoms2} confirmVaxMatch={this.state.confirmvaxmatch2}
                residesInCarehome={this.state.carehomeresident} cannotLocateOnNivs={this.state.cannotlocateonnivs2} confirmStaffCheck={this.state.confirmstaffcheck2} confirmFluVaxCheck={this.state.confirmfluvaxcheck2}
                updateAdminFields={this.updateAdminFields} submitting={this.state.submitting} saveCallback={this.props.returnCallback} finishedVaccinationCallback={this.props.finishedVaccinationCallback} cancelScreeningCallback={this.props.cancelScreeningCallback} cancelPrescribingCallback={this.props.cancelPrescribingCallback} cancelAdministeringCallback={this.props.cancelAdministeringCallback}
                parentConsentForVaccine={this.state.parentconsentforvaccine2}
                parentMoreInformationNeededBeforeConsent={this.state.parentmoreinformationneededbeforeconsent2}
                guardianFullName={this.state.guardianfullname2}
                guardianRelationshipToRecipient={this.state.guardianrelationshiptorecipient2}
                patientConsentForVaccine={this.state.patientconsentforvaccine2}
                moreInformationNeededBeforeConsent={this.state.moreinformationneededbeforeconsent2}
                satisfiedWithConsent={this.state.satisfiedwithconsent2}
                outcomeAfterConsentDiscussion={this.state.outcomeafterconsentdiscussion2}
                thirtyandfirstdose={this.state.thirtyandfirstdose2}
                lowplateletcountafterfirst={this.state.lowplateletcountafterfirst2}
                historyofthrombosis={this.state.historyofthrombosis2}
                historyofbleedingdisorder={this.state.historyofbleedingdisorder2}
                underageandfirstdose={this.state.underageandfirstdose2}
                capillaryleaksyndrome={this.state.capillaryleaksyndrome2}
            />;
        } else if(fluDose) {
            return <CovidVaccinationPSDPrint role={this.state.role} disallowAdministering={readonly} vaccineType={this.state.vaccinetypeflu1} appointmentType={this.state.flu1appointmenttype} showingAsAdditionalPsd={showingAsAdditional} multiplePsds={multiplePsds} changeHandler={this.rawChangeHandler} loggedInUserName={this.state.username} patientFirstName={this.state.forename} patientLastName={this.state.lastname} patientDateofBirth={this.state.dateofbirth} patientNHSNumber={this.state.nhsnumber} patientHomeAddress={this.state.address} patientPostCode={this.state.postcode} gender={this.state.gender} ethnicity={this.state.ethnicity} carer={isCarerVal} isHealthcareWorker={this.state.healthcareworker} worksInCareHome={this.state.carehomeworker} organisation={organisation} staffRole={this.state.jobrole} PSDFirstDose="No" PSDSecondDose="No" PSDThirdDose="No" PSDBoosterDose="No" PSDFluDose="Yes" BatchNo="" VaccineExpiry="" CurrentDate={currentDate}
            seriousreaction={this.state.seriousreactionflu1} allergic={this.state.allergicflu1 === "Yes" ? (this.state.allergicconfirmedflu1 === "Yes" ? "No" : "Yes") : this.state.allergicflu1} infectionorfeverish={this.state.infectionorfeverishflu1} bleedingdisorder={this.state.bleedingdisorderflu1 === "Yes" ? (this.state.bleedingdisorderconfirmedflu1 === "Yes" ? "No" : "Yes") : this.state.bleedingdisorderflu1} pregnant={this.state.pregnantflu1} breastfeeding={this.state.breastfeedingflu1} covidtrial={this.state.covidtrialflu1 === "Yes" ? (this.state.covidtrialapprovedflu1 === "Yes" ? "No" : "Yes") : this.state.covidtrialflu1} flujabpast7days={this.state.flujabpast7daysflu1} covidpositive4weeks={this.state.covidpositive4weeksflu1} bcgpast3months={this.state.bcgpast3monthsflu1}
            assessorName={this.state.assessornameflu1} assessorAdAccount={this.state.assessoradaccountflu1} assessorTimestamp={this.state.assessortimestampflu1} assessorComments={this.state.assessorcommentsflu1}
            prescriberName={this.state.prescribernameflu1} prescriberAdAccount={this.state.prescriberadaccountflu1} prescriberTimestamp={this.state.prescribertimestampflu1} prescriberComments={this.state.prescribercommentsflu1} prescriberRegistrationNumber={this.state.prescriberregistrationnumberflu1}
            vaccinatorName={this.state.vaccinatornameflu1} vaccinatorAdAccount={this.state.vaccinatoradaccountflu1} vaccinatorTimestamp={this.state.vaccinatortimestampflu1} vaccinatorComments={this.state.vaccinatorcommentsflu1}
            batchNumber={this.state.batchnumberflu1} batchExpiry={this.state.batchexpiryflu1} vaccinationSite={this.state.vaccinationsiteflu1} doseNumber={this.state.dosenumberflu1} dtcReference={this.state.dtcreferenceflu1} covidSymptoms={this.state.covidsymptomsflu1} confirmVaxMatch={this.state.confirmvaxmatchflu1}
            residesInCarehome={this.state.carehomeresident} cannotLocateOnNivs={this.state.cannotlocateonnivsflu1} confirmStaffCheck={this.state.confirmstaffcheckflu1} confirmCovidVaxCheck={this.state.confirmcovidvaxcheckflu1} requiredCovidDose={this.state.requiredcoviddoseflu1}
            updateAdminFields={this.updateAdminFields} submitting={this.state.submitting} saveCallback={this.props.returnCallback} finishedVaccinationCallback={this.props.finishedVaccinationCallback} cancelScreeningCallback={this.props.cancelScreeningCallback} cancelPrescribingCallback={this.props.cancelPrescribingCallback} cancelAdministeringCallback={this.props.cancelAdministeringCallback}
            parentConsentForVaccine={this.state.parentconsentforvaccineflu1}
            parentMoreInformationNeededBeforeConsent={this.state.parentmoreinformationneededbeforeconsentflu1}
            guardianFullName={this.state.guardianfullnameflu1}
            guardianRelationshipToRecipient={this.state.guardianrelationshiptorecipientflu1}
            patientConsentForVaccine={this.state.patientconsentforvaccineflu1}
            moreInformationNeededBeforeConsent={this.state.moreinformationneededbeforeconsentflu1}
            satisfiedWithConsent={this.state.satisfiedwithconsentflu1}
            outcomeAfterConsentDiscussion={this.state.outcomeafterconsentdiscussionflu1}
            thirtyandfirstdose={this.state.thirtyandfirstdoseflu1}
            lowplateletcountafterfirst={this.state.lowplateletcountafterfirstflu1}
            historyofthrombosis={this.state.historyofthrombosisflu1}
            historyofbleedingdisorder={this.state.historyofbleedingdisorderflu1}
            underageandfirstdose={this.state.underageandfirstdoseflu1}
            medicalcondition={this.state.medicalconditionflu1}
        />
        } else {
            return <CovidVaccinationPSDPrint role={this.state.role} disallowAdministering={readonly} vaccineType={this.state.vaccinetype} appointmentType={this.state.firstappointmenttype} showingAsAdditionalPsd={showingAsAdditional} multiplePsds={multiplePsds} changeHandler={this.rawChangeHandler} loggedInUserName={this.state.username} patientFirstName={this.state.forename} patientLastName={this.state.lastname} patientDateofBirth={this.state.dateofbirth} patientNHSNumber={this.state.nhsnumber} patientHomeAddress={this.state.address} patientPostCode={this.state.postcode} gender={this.state.gender} ethnicity={this.state.ethnicity} carer={isCarerVal} isHealthcareWorker={this.state.healthcareworker} worksInCareHome={this.state.carehomeworker} organisation={organisation} staffRole={this.state.jobrole} PSDFirstDose="Yes" PSDSecondDose="No" PSDThirdDose="No" PSDBoosterDose="No" PSDFluDose="No" BatchNo="" VaccineExpiry="" CurrentDate={currentDate}
                seriousreaction={this.state.seriousreaction} allergic={this.state.allergic === "Yes" ? (this.state.allergicconfirmed === "Yes" ? "No" : "Yes") : this.state.allergic} infectionorfeverish={this.state.infectionorfeverish} bleedingdisorder={this.state.bleedingdisorder === "Yes" ? (this.state.bleedingdisorderconfirmed === "Yes" ? "No" : "Yes") : this.state.bleedingdisorder} pregnant={this.state.pregnant} breastfeeding={this.state.breastfeeding} covidtrial={this.state.covidtrial === "Yes" ? (this.state.covidtrialapproved === "Yes" ? "No" : "Yes") : this.state.covidtrial} flujabpast7days={this.state.flujabpast7days} covidpositive4weeks={this.state.covidpositive4weeks} covidpositive12weeks={this.state.covidpositive12weeks} bcgpast3months={this.state.bcgpast3months}
                assessorName={this.state.assessorname} assessorAdAccount={this.state.assessoradaccount} assessorTimestamp={this.state.assessortimestamp} assessorComments={this.state.assessorcomments}
                prescriberName={this.state.prescribername} prescriberAdAccount={this.state.prescriberadaccount} prescriberTimestamp={this.state.prescribertimestamp} prescriberComments={this.state.prescribercomments} prescriberRegistrationNumber={this.state.prescriberregistrationnumber}
                vaccinatorName={this.state.vaccinatorname} vaccinatorAdAccount={this.state.vaccinatoradaccount} vaccinatorTimestamp={this.state.vaccinatortimestamp} vaccinatorComments={this.state.vaccinatorcomments}
                batchNumber={this.state.batchnumber} batchExpiry={this.state.batchexpiry} vaccinationSite={this.state.vaccinationsite} doseNumber={this.state.dosenumber} dtcReference={this.state.dtcreference} covidSymptoms={this.state.covidsymptoms} confirmVaxMatch={this.state.confirmvaxmatch}
                residesInCarehome={this.state.carehomeresident} cannotLocateOnNivs={this.state.cannotlocateonnivs} confirmStaffCheck={this.state.confirmstaffcheck} confirmFluVaxCheck={this.state.confirmfluvaxcheck}
                updateAdminFields={this.updateAdminFields} submitting={this.state.submitting} saveCallback={this.props.returnCallback} finishedVaccinationCallback={this.props.finishedVaccinationCallback} cancelScreeningCallback={this.props.cancelScreeningCallback} cancelPrescribingCallback={this.props.cancelPrescribingCallback} cancelAdministeringCallback={this.props.cancelAdministeringCallback}
                parentConsentForVaccine={this.state.parentconsentforvaccine}
                parentMoreInformationNeededBeforeConsent={this.state.parentmoreinformationneededbeforeconsent}
                guardianFullName={this.state.guardianfullname}
                guardianRelationshipToRecipient={this.state.guardianrelationshiptorecipient}
                patientConsentForVaccine={this.state.patientconsentforvaccine}
                moreInformationNeededBeforeConsent={this.state.moreinformationneededbeforeconsent}
                satisfiedWithConsent={this.state.satisfiedwithconsent}
                outcomeAfterConsentDiscussion={this.state.outcomeafterconsentdiscussion}
                thirtyandfirstdose={this.state.thirtyandfirstdose}
                lowplateletcountafterfirst={this.state.lowplateletcountafterfirst}
                historyofthrombosis={this.state.historyofthrombosis}
                historyofbleedingdisorder={this.state.historyofbleedingdisorder}
                underageandfirstdose={this.state.underageandfirstdose}
                capillaryleaksyndrome={this.state.capillaryleaksyndrome}
            />;
        }
    }

    


    render() {

        const { submission, returnCallback, psdRole, additionalPsd, currentDose, multiplePsds } = this.props;

        if (this.state.viewAsPsd) {
            let isReadOnly = false;
            let consentDosePostfix = "";
            let appointmentNumber = "first";
            let otherCovidVaccineAvailable = false;
            if (this.boost1AppointmentAdminQuestionsAvailable()) {
                appointmentNumber = "boost1";
                otherCovidVaccineAvailable = true;
            } else if (this.thirdAppointmentAdminQuestionsAvailable()) {
                appointmentNumber = "third";
                otherCovidVaccineAvailable = true;
            } else if (this.secondAppointmentAdminQuestionsAvailable()) {
                appointmentNumber = "second";
                otherCovidVaccineAvailable = true;
            }
            if (!otherCovidVaccineAvailable && this.flu1AppointmentAdminQuestionsAvailable()) {
                appointmentNumber = "flu1";
            }

            if (currentDose) {
                // this sets the answer postfix based on current dose string to ensure the correct consent answer is checked
                if (currentDose === "second") {
                    consentDosePostfix = "2";
                } else if (currentDose === "third") {
                    consentDosePostfix = "3";
                } else if (currentDose !== "first") {
                    consentDosePostfix = currentDose;
                }
                appointmentNumber = currentDose;
                if (psdRole === "administering" && (this.state["satisfiedwithconsent" + consentDosePostfix] === "No" || (!this.state["prescriberadaccount" + consentDosePostfix] && this.state["confirmvaxmatch" + consentDosePostfix] === "No"))) {
                    isReadOnly = true;
                }
                //if (currentDose !== originalDose) {
                //    showingAsAdditional = true;
                //}                
            }

            return <>
                {this.renderPsd(appointmentNumber, isReadOnly, additionalPsd, multiplePsds)}
            </>
        }

        return <div className="questionnaire-body">
            <div id="screeningAdminView">
                {returnCallback && <div><Button color="secondary" onClick={() => returnCallback()} disabled={this.state.submitting}>Return to list</Button></div>}
                <div className="questionnaire-submission-summary">This submission was recorded on <strong><Moment format="DD MMM YYYY HH:mm:ss">{submission.submissionDate}</Moment></strong>. Submission identifier: <strong>{submission.submissionIdentifier}</strong>.</div>
                <div className="questionnaire-error"><strong><h3>SCREENING IS NOW COMPLETED IN NIVS. PLEASE ACCESS NIVS TO VIEW OR COMPLETE SCREENING.</h3></strong></div>
                <h3>Pre-Screening Questions</h3>
                <div className="col-12">There is a national process to prioritise vaccination for those people who are either most at risk or who need it because of the work they do. This includes those people over the age of 70, those who are classed as clinically extremely vulnerable and people working in healthcare, social care and care homes.</div>
                {!this.isEditing("vip") && this.state.hasVipAccess && <Question questionNumber="pre-0" questionText={"Is this a VIP record?"}>
                    <FormGroup className="questionnaire-answers">
                        <Label>{this.renderAnswer(submission.questionAnswers, "vip")}</Label>
                        <Button color="link" onClick={() => this.editField("vip")}>change</Button>
                    </FormGroup>
                </Question>}
                {this.isEditing("vip") && <Question questionNumber="pre-0" questionText={"Is this a VIP record?"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="vip" value="Yes" onChange={(e) => this.radioChangeHandler(e, "vip")} />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="vip" value="No" onChange={(e) => this.radioChangeHandler(e, "vip")} />No
                        </Label>
                    </FormGroup>
                </Question>}
                {!this.isEditing("eligible") && <Question questionNumber="pre-1" questionText={"Please indicate which of the priority groups you fall into?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label>{this.renderEligibleAnswer(submission.questionAnswers)}</Label>
                        <Button color="link" onClick={() => this.editField("eligible")}>change</Button>
                    </FormGroup>
                </Question>}
                {this.isEditing("eligible") && <Question questionNumber="pre-1" questionText={"Please indicate which of the priority groups you fall into?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="CarehomeWorker" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Carehome Worker
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="SocialCareWorker" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Frontline Social Care Worker
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="HealthcareWorker" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Frontline Healthcare Worker
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="80" onChange={(e) => this.radioChangeHandler(e, "eligible")} />80 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="75" onChange={(e) => this.radioChangeHandler(e, "eligible")} />75 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="70" onChange={(e) => this.radioChangeHandler(e, "eligible")} />70 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="ClinVulnerable" onChange={(e) => this.radioChangeHandler(e, "eligible")} />18 to 69 years of age and clinically extremely vulnerable
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="65" onChange={(e) => this.radioChangeHandler(e, "eligible")} />65 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="16To64Underlying" onChange={(e) => this.radioChangeHandler(e, "eligible")} />16 to 64 years with underlying health conditions which put them at higher risk of serious disease and mortality
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="60" onChange={(e) => this.radioChangeHandler(e, "eligible")} />60 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="55" onChange={(e) => this.radioChangeHandler(e, "eligible")} />55 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="50" onChange={(e) => this.radioChangeHandler(e, "eligible")} />50 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="40" onChange={(e) => this.radioChangeHandler(e, "eligible")} />40 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="30" onChange={(e) => this.radioChangeHandler(e, "eligible")} />30 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="18" onChange={(e) => this.radioChangeHandler(e, "eligible")} />18 years of age and over
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="16CEV" onChange={(e) => this.radioChangeHandler(e, "eligible")} />16 to 17 years of age and clinically extremely vulnerable
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="16" onChange={(e) => this.radioChangeHandler(e, "eligible")} />16 to 17 years of age
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="12CEV" onChange={(e) => this.radioChangeHandler(e, "eligible")} />12 to 15 years of age and clinically extremely vulnerable
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="12" onChange={(e) => this.radioChangeHandler(e, "eligible")} />12 to 15 years of age
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="5CEV" onChange={(e) => this.radioChangeHandler(e, "eligible")} />5 to 11 years of age and clinically extremely vulnerable
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="5" onChange={(e) => this.radioChangeHandler(e, "eligible")} />5 to 11 years of age
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="eligible" value="Carer" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Carer of somebody in <strong>any of the above</strong> priority groups
                            </Label>
                    </FormGroup>
                </Question>}
                {!this.isEditing("healthcareworker") && <Question questionNumber="pre-2" questionText={"Are you a Frontline Healthcare Worker?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label>{this.renderAnswer(submission.questionAnswers, "healthcareworker")}</Label>
                        <Button color="link" onClick={() => this.editField("healthcareworker")}>change</Button>
                    </FormGroup>
                </Question>}
                {this.isEditing("healthcareworker") && <Question questionNumber="pre-2.1" questionText={"Are you a Frontline Healthcare Worker?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="healthcareworker" value="Yes" checked={this.state.healthcareworker === "Yes"} onChange={(e) => this.radioChangeHandler(e, "healthcareworker")} disabled />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="healthcareworker" value="No" checked={this.state.healthcareworker === "No"} onChange={(e) => this.radioChangeHandler(e, "healthcareworker")} disabled />No
                        </Label>
                    </FormGroup>
                </Question>}
                {!this.isEditing("carehomeworker") && <Question questionNumber="pre-2.2" questionText={"Are you a Care Home Worker?"} isMandatory={this.isMandatory('carehomeworker')} unanswered={this.state.initialised && !this.state.carehomeworker}>
                    <FormGroup className="questionnaire-answers">
                        <Label>{this.renderAnswer(submission.questionAnswers, "carehomeworker")}</Label>
                        {this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes" }) ? <Button color="link" onClick={() => this.editField("carehomeworker")}>change</Button> : null}
                    </FormGroup>
                </Question>}
                {this.isEditing("carehomeworker") && <Question questionNumber="pre-2.2" questionText={"Are you a Care Home Worker?"} isMandatory={this.isMandatory('carehomeworker')} unanswered={this.state.initialised && !this.state.carehomeworker}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="carehomeworker" value="Yes" checked={this.state.carehomeworker === "Yes"} onChange={(e) => this.radioChangeHandler(e, "carehomeworker")} disabled />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="carehomeworker" value="No" checked={this.state.carehomeworker === "No"} onChange={(e) => this.radioChangeHandler(e, "carehomeworker")} disabled />No
                        </Label>
                    </FormGroup>
                </Question>}
                <Question questionNumber="pre-2.3" questionText={"Care Home you are registering for:"} isMandatory={this.isMandatory('carehomeorganisation')} unanswered={this.state.initialised && !this.state.carehomeorganisation}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("carehomeorganisation") ? <Label>{this.getCareHomeOrganisation(submission.questionAnswers, "carehomeorganisation")}</Label> : null}
                        {!this.isEditing("carehomeorganisation") && this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "Yes" }) ? <Button color="link" onClick={() => this.editField("carehomeorganisation")}>change</Button> : null}
                        {this.isEditing("carehomeorganisation") ? <Label check><Input type="select" name="carehomeorganisation" onChange={(e) => this.textChangeHandler(e, "carehomeorganisation", true)} value={this.state.carehomeorganisation}>
                            <option value="">Please select</option>
                            {this.state.carehomeOrganisationList.map((di, idx) => <option key={idx} value={di.id}>{di.name}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question>
                {!this.isEditing("onbehalfof") && <Question questionNumber="pre-3.1" questionText={"Is this information being provided by someone other than the patient?"} isMandatory={this.isMandatory('onbehalfof')}>
                    <FormGroup className="questionnaire-answers">
                        <Label>{this.renderAnswer(submission.questionAnswers, "onbehalfof")}</Label>
                        {this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"] }) ? <Button color="link" onClick={() => this.editField("onbehalfof")}>change</Button> : null}
                    </FormGroup>
                </Question>}
                {this.isEditing("onbehalfof") && <Question questionNumber="pre-3.1" questionText={"Is this information being provided by someone other than the patient?"} isMandatory={this.isMandatory('onbehalfof')}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="onbehalfof" value="Yes" onChange={(e) => this.radioChangeHandler(e, "onbehalfof")} />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="onbehalfof" value="No" onChange={(e) => this.radioChangeHandler(e, "onbehalfof")} />No
                        </Label>
                    </FormGroup>
                </Question>}
                <Question questionNumber="pre-3.2" questionText={"Name of person providing information and their relationship to the patient"} isMandatory={this.isMandatory('onbehalfofrelationship')} isMandatory={this.isMandatory('onbehalfofrelationship')} unanswered={this.state.initialised && !this.state.onbehalfofrelationship}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("onbehalfofrelationship") ? <Label>{this.renderAnswer(submission.questionAnswers, "onbehalfofrelationship")}</Label> : null}
                        {!this.isEditing("onbehalfofrelationship") && this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], onBehalfOfAnswered: true, onBehalfOf: "Yes" }) ? <Button color="link" onClick={() => this.editField("onbehalfofrelationship")}>change</Button> : null}
                        {this.isEditing("onbehalfofrelationship") ? <Label check><Input type="text" name="onbehalfofrelationship" value={this.state.onbehalfofrelationship} autoFocus onChange={(e) => this.textChangeHandler(e, "onbehalfofrelationship", true)} maxLength={120} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="pre-3" questionText={"Name of vaccination booking team member completing form:"} isMandatory={this.isMandatory('onbehalfofname')} unanswered={this.state.initialised && !this.state.onbehalfofname}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("onbehalfofname") ? <Label>{this.renderAnswer(submission.questionAnswers, "onbehalfofname")}</Label> : null}
                        {!this.isEditing("onbehalfofname") && this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], onBehalfOfAnswered: true }) ? <Button color="link" onClick={() => this.editField("onbehalfofname")}>change</Button> : null}
                        {this.isEditing("onbehalfofname") ? <Label check><Input type="text" name="onbehalfofname" value={this.state.onbehalfofname} autoFocus onChange={(e) => this.textChangeHandler(e, "onbehalfofname", true)} maxLength={30} /></Label> : null}
                    </FormGroup>
                </Question>
                {!this.isEditing("willingtopartake") && <Question questionNumber="1.1a" questionText={"Are you willing to take part in the COVID-19 Vaccination programme and will you be available for your second vaccination at the agreed interval?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label>{this.renderAnswer(submission.questionAnswers, "willingtopartake")}</Label>
                        {this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"] }) ? <Button color="link" onClick={() => this.editField("willingtopartake")}>change</Button> : null}
                    </FormGroup>
                </Question>}
                {this.isEditing("willingtopartake") && <Question questionNumber="1.1a" questionText={"Are you willing to take part in the COVID-19 Vaccination programme and will you be available for your second vaccination at the agreed interval?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="willingtopartake" value="Yes" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="willingtopartake" value="No" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />No
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="willingtopartake" value="Not Yet" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />Yes, but NOT at this time
                        </Label>
                    </FormGroup>
                </Question>}
                <h3 ref={(ref) => this.personalDetailsRef = ref}>Person Details</h3>
                <Question questionNumber="2.1a" questionText={"NHS Number"} isMandatory={this.isMandatory('nhsnumber')} unanswered={this.state.initialised && !this.state.nhsnumber}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("nhsnumber") ? <Label>{this.renderAnswer(submission.questionAnswers, "nhsnumber")}</Label> : null}
                        {!this.isEditing("nhsnumber") ? <Button color="link" onClick={() => this.editField("nhsnumber")}>change</Button> : null}
                        {this.isEditing("nhsnumber") ? <Label check><Input type="text" name="nhsnumber" value={this.state.nhsnumber} onChange={(e) => this.textChangeHandler(e, "nhsnumber", true)} maxLength={10} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.1b.i" questionText={"Forename"} isMandatory={this.isMandatory('forename')} unanswered={this.state.initialised && !this.state.forename}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("forename") ? <Label>{this.renderAnswer(submission.questionAnswers, "forename")}</Label> : null}
                        {!this.isEditing("forename") ? <Button color="link" onClick={() => this.editField("forename")}>change</Button> : null}
                        {this.isEditing("forename") ? <Label check><Input type="text" name="forename" value={this.state.forename} autoFocus onChange={(e) => this.textChangeHandler(e, "forename", true)} maxLength={30} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.1b.ii" questionText={"Last name"} isMandatory={this.isMandatory('lastname')} unanswered={this.state.initialised && !this.state.lastname}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("lastname") ? <Label>{this.renderAnswer(submission.questionAnswers, "lastname")}</Label> : null}
                        {!this.isEditing("lastname") ? <Button color="link" onClick={() => this.editField("lastname")}>change</Button> : null}
                        {this.isEditing("lastname") ? <Label check><Input type="text" name="lastname" value={this.state.lastname} autoFocus onChange={(e) => this.textChangeHandler(e, "lastname", true)} maxLength={30} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.1c" questionText={"Date of birth"} isMandatory={this.isMandatory('dateofbirth')} unanswered={this.state.initialised && !this.state.dateofbirth}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("dateofbirth") ? <Label>{this.renderAnswer(submission.questionAnswers, "dateofbirth")}</Label> : null}
                        {!this.isEditing("dateofbirth") ? <Button color="link" onClick={() => this.editField("dateofbirth")}>change</Button> : null}
                        {this.isEditing("dateofbirth") ? <Label check><Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" value={this.state.dateofbirth} onChange={(e) => this.textChangeHandler(e, "dateofbirth", true)} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.1d" questionText={"Gender"} isMandatory={this.isMandatory('gender')} unanswered={this.state.initialised && !this.state.gender}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("gender") ? <Label>{this.renderAnswer(submission.questionAnswers, "gender")}</Label> : null}
                        {!this.isEditing("gender") ? <Button color="link" onClick={() => this.editField("gender")}>change</Button> : null}
                        {this.isEditing("gender") ? <Label check><Input type="select" name="gender" onChange={(e) => this.textChangeHandler(e, "gender")} value={this.state.gender}>
                            <option value="">Please select</option>
                            {this.state.genderList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.1e" questionText={"Please select your Ethnic Group:"} isMandatory={this.isMandatory('ethnicity')} unanswered={this.state.initialised && !this.state.ethnicity}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("ethnicity") ? <Label>{this.renderAnswer(submission.questionAnswers, "ethnicity")}</Label> : null}
                        {!this.isEditing("ethnicity") ? <Button color="link" onClick={() => this.editField("ethnicity")}>change</Button> : null}
                        {this.isEditing("ethnicity") ? <Label check><Input type="select" name="ethnicity" onChange={(e) => this.textChangeHandler(e, "ethnicity")} value={this.state.ethnicity}>
                            <option value="">Please select</option>
                            {this.state.ethnicityList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.1f" questionText={"Postcode of residence"} isMandatory={this.isMandatory('postcode')} unanswered={this.state.initialised && !this.state.postcode}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("postcode") ? <Label>{this.renderAnswer(submission.questionAnswers, "postcode")}</Label> : null}
                        {!this.isEditing("postcode") && this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes" }) ? <Button color="link" onClick={() => this.editField("postcode")}>change</Button> : null}
                        {this.isEditing("postcode") ? <Label check><Input type="text" name="postcode" autoFocus value={this.state.postcode} onChange={(e) => this.textChangeHandler(e, "postcode", true)} maxLength={10} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.1g" questionText={"Mobile number"} isMandatory={this.isMandatory('mobile')}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("mobile") ? <Label>{this.renderAnswer(submission.questionAnswers, "mobile")}</Label> : null}
                        {!this.isEditing("mobile") && this.showIf({ eligibleAnswered: true, eligible: ["Yes", "CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes" }) ? <Button color="link" onClick={() => this.editField("mobile")}>change</Button> : null}
                        {this.isEditing("mobile") ? <Label check><Cleave name="mobile" placeholder="07911 123456" value={this.state.mobile} options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeRawHandler(e, "mobile")} maxLength={15} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.2a.i" questionText={"Organisation:"} isMandatory={this.isMandatory('organisation')} unanswered={this.state.initialised && !this.state.organisation}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("organisation") ? <Label>{this.renderAnswer(submission.questionAnswers, "organisation")}</Label> : null}
                        {!this.isEditing("organisation") ? <Button color="link" onClick={() => this.editField("organisation")}>change</Button> : null}
                        {this.isEditing("organisation") ? <Label check><Input type="select" name="organisation" onChange={(e) => this.textChangeHandler(e, "organisation", true)} value={this.state.organisation}>
                            <option value="">Please select</option>
                            {this.state.organisationList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.2a.ii" questionText={"Directorate:"} isMandatory={this.isMandatory('directorate')} unanswered={this.state.initialised && !this.state.directorate}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("directorate") ? <Label>{this.renderAnswer(submission.questionAnswers, "directorate")}</Label> : null}
                        {!this.isEditing("directorate") ? <Button color="link" onClick={() => this.editField("directorate")}>change</Button> : null}
                        {this.isEditing("directorate") ? <Label check><Input type="select" name="directorate" onChange={(e) => this.textChangeHandler(e, "directorate", true)} value={this.state.directorate}>
                            <option value="">Please select</option>
                            {this.state.directorateList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.2b" questionText={"Job Role:"} isMandatory={this.isMandatory('jobrole')} unanswered={this.state.initialised && !this.state.jobrole}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("jobrole") ? <Label>{this.renderAnswer(submission.questionAnswers, "jobrole")}</Label> : null}
                        {!this.isEditing("jobrole") ? <Button color="link" onClick={() => this.editField("jobrole")}>change</Button> : null}
                        {this.isEditing("jobrole") ? <Label check><Input type="select" name="jobrole" onChange={(e) => this.textChangeHandler(e, "jobrole", true)} value={this.state.jobrole} >
                            <option value="">Please select</option>
                            {this.state.jobRoleList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.2c" questionText={"Department or Ward:"} isMandatory={this.isMandatory('departmentward')}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("departmentward") ? <Label>{this.renderAnswer(submission.questionAnswers, "departmentward")}</Label> : null}
                        {!this.isEditing("departmentward") ? <Button color="link" onClick={() => this.editField("departmentward")}>change</Button> : null}
                        {this.isEditing("departmentward") ? <Label check><Input type="text" name="departmentward" value={this.state.departmentward} onChange={(e) => this.textChangeHandler(e, "departmentward", true)} maxLength={50} /></Label> : null}
                    </FormGroup>
                </Question>
                <Question questionNumber="2.2d" questionText={"Please let us know the reason why you are not willing to take part in this programme."} isMandatory={this.isMandatory('reason')}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("reason") ? <Label>{this.renderAnswer(submission.questionAnswers, "reason")}</Label> : null}
                        {!this.isEditing("reason") && (this.showIf({ willingToPartakeAnswered: true, willingToPartake: "No" }) || this.showIf({ willingToPartakeAnswered: true, willingToPartake: "Not Yet" })) ? <Button color="link" onClick={() => this.editField("reason")}>change</Button> : null}
                        {this.isEditing("reason") ? <Label check><Input type="textarea" name="reason" value={this.state.reason} onChange={(e) => this.textChangeHandler(e, "reason", true)} maxLength={255} cols={80} rows={2} /></Label> : null}
                    </FormGroup>
                </Question>
                <h3>Appointment Scheduling</h3>
                {!this.isEditing("restrictedmobility") && <Question questionNumber="1.2" questionText={"Do you have restricted mobility, or do you use a walking aid, mobility scooter or wheelchair?"} isMandatory={this.isMandatory('restrictedmobility')} unanswered={this.state.initialised && !this.state.restrictedmobility}>
                    <FormGroup className="questionnaire-answers">
                        <Label>{this.renderAnswer(submission.questionAnswers, "restrictedmobility")}</Label>
                        <Button color="link" onClick={() => this.editField("restrictedmobility")}>change</Button>
                    </FormGroup>
                </Question>}
                {this.isEditing("restrictedmobility") && <Question questionNumber="1.2" questionText={"Do you have restricted mobility, or do you use a walking aid, mobility scooter or wheelchair?"} isMandatory={this.isMandatory('restrictedmobility')} unanswered={this.state.initialised && !this.state.restrictedmobility}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="restrictedmobility" value="Yes" onChange={(e) => this.radioChangeHandler(e, "restrictedmobility")} />Yes
                            </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="restrictedmobility" value="No" onChange={(e) => this.radioChangeHandler(e, "restrictedmobility")} />No
                            </Label>
                    </FormGroup>
                </Question>}
                <Question questionNumber="1.3a.i" questionText={"Is ONLY the FIRST appointment required?"}>
                    <FormGroup className="questionnaire-answers">
                        <Label><Input type="checkbox" name="firstappointmentonly" value="Yes" checked={this.state.firstappointmentonly === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "firstappointmentonly")} disabled={this.state.secondappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes"} />Yes</Label>
                    </FormGroup>
                </Question>
                <Question questionNumber="1.3a.ii" questionText={"Is ONLY the SECOND appointment required?"}>
                    <FormGroup className="questionnaire-answers">
                        <Label><Input type="checkbox" name="secondappointmentonly" value="Yes" checked={this.state.secondappointmentonly === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "secondappointmentonly")} disabled={this.state.firstappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes"} />Yes</Label>
                    </FormGroup>
                </Question>
                <Question questionNumber="1.3a.iii" questionText={"Is ONLY an ALTERNATIVE vaccination dose/type required?"}>
                    <FormGroup className="questionnaire-answers">
                        <Label><Input type="checkbox" name="thirdappointmentonly" value="Yes" checked={this.state.thirdappointmentonly === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "thirdappointmentonly")} disabled={this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes"} />Yes</Label>
                    </FormGroup>
                </Question>
                <div><strong><h4>1st Dose Appointment</h4></strong></div>
                <Row>
                    <Col><Question questionNumber="1.3a.iiii" questionText={"Please select a site for your first vaccination appointment."} isMandatory={this.isMandatory('firstappointmentsite') && (this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.firstappointmentsite}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("firstappointmentsite") ? <Label>{this.renderAnswer(submission.questionAnswers, "firstappointmentsite")}</Label> : null}
                        {!this.isEditing("firstappointmentsite") ? <Button color="link" onClick={() => this.editField("firstappointmentsite", () => this.getAvailableSitesForFirstAppointment(() => { this.editField("firstappointmentlocation", () => this.getAvailableLocationsForSecondAppointment(() => { this.getAvailableLocationsForFirstAppointment(() => { this.editField("firstappointmentdate", () => this.editField("firstappointmenttimeslot", () => this.getSchedulesForFirstAppointment(() => this.getAvailableTimeSlotsForFirstAppointment(() => { if (!this.state.allowDateOverride && this.state.firstappointmentonly !== "Yes") { this.editField("secondappointmentsite", this.getAvailableSitesForSecondAppointment(() => { this.editField("secondappointmentlocation", () => this.getAvailableLocationsForSecondAppointment(() => { this.editField("secondappointmentdate", () => this.editField("secondappointmenttimeslot", () => this.getSchedulesForFollowUpAppointment(() => this.getAvailableTimeSlotsForSecondAppointment(() => this.syncFollowUpAppointmentSiteLocation())))) })); })) } })))) }); })) }))}>change</Button> : null}
                        {this.isEditing("firstappointmentsite") ? <Label check><Input type="select" name="firstappointmentsite" onChange={(e) => this.textChangeHandler(e, "firstappointmentsite", true)} value={this.state.firstappointmentsite} disabled={this.state.secondappointmentonly === "Yes" || this.state.prescribertimestamp} >
                            <option value="">Please select</option>
                            {this.state.firstAppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question></Col>
                <Col><Question questionNumber="1.3b" questionText={"Please select a location for your first vaccination appointment."} isMandatory={this.isMandatory('firstappointmentlocation') && (this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.firstappointmentlocation}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("firstappointmentlocation") ? <Label>{this.renderAnswer(submission.questionAnswers, "firstappointmentlocation")}</Label> : null}
                        {!this.isEditing("firstappointmentlocation") ? <Button color="link" onClick={() => this.editField("firstappointmentlocation", () => this.getAvailableLocationsForSecondAppointment(() => { this.getAvailableLocationsForFirstAppointment(() => { this.editField("firstappointmentdate", () => this.editField("firstappointmenttimeslot", () => this.getSchedulesForFirstAppointment(() => this.getAvailableTimeSlotsForFirstAppointment(() => { if (!this.state.allowDateOverride && this.state.firstappointmentonly !== "Yes") { this.editField("secondappointmentlocation", () => this.getAvailableLocationsForSecondAppointment(() => { this.editField("secondappointmentdate", () => this.editField("secondappointmenttimeslot", () => this.getSchedulesForFollowUpAppointment(() => this.getAvailableTimeSlotsForSecondAppointment(() => this.syncFollowUpAppointmentSiteLocation())))) })) } })))) }); }))}>change</Button> : null}
                        {this.isEditing("firstappointmentlocation") ? <Label check><Input type="select" name="firstappointmentlocation" onChange={(e) => this.textChangeHandler(e, "firstappointmentlocation", true)} value={this.state.firstappointmentlocation} disabled={!this.state.firstappointmentsite || this.state.secondappointmentonly === "Yes" || this.state.prescribertimestamp} >
                            <option value="">Please select</option>
                            {this.state.firstAppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input></Label> : null}
                        {this.isEditing("firstappointmentlocation") && <div className="questionnaire-error"><strong>IMPORTANT: Please ensure the follow up appointment site and location is updated to match to ensure vaccines are not mismatched!</strong></div>}
                    </FormGroup>
                </Question></Col>
                <Col><Question questionNumber="1.3c" questionText={"Please select an available date for first vaccination appointment."} isMandatory={this.isMandatory('firstappointmentdate') && (this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.firstappointmentdate}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("firstappointmentdate") ? <Label>{this.renderAnswer(submission.questionAnswers, "firstappointmentdate")}</Label> : null}
                        {!this.isEditing("firstappointmentdate") ? <Button color="link" onClick={() => this.editField("firstappointmentdate", () => this.editField("firstappointmenttimeslot", () => this.getSchedulesForFirstAppointment(() => this.getAvailableTimeSlotsForFirstAppointment())))}>change</Button> : null}
                        {(!this.isEditing("firstappointmentdate") && this.state.vaccinetype && this.state.firstappointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetype)}</span> : null}
                        {this.isEditing("firstappointmentdate") && this.state.allowDateOverride ? <div className="date-override-container"><Label check><Input type="checkbox" name="firstAppointmentDateOverride" value="Yes" checked={this.state.firstAppointmentDateOverride === "Yes"} onChange={(e) => this.checkedChangeNoStoreHandler(e, "firstappointmentdateoverride")} />Ignore date range restrictions (appointment slot capacity still applies)</Label></div> : null}
                        {this.isEditing("firstappointmentdate") ? <Label check><DatePicker className="form-control date-selection-inputbox" selected={this.state.firstappointmentdate} filterDate={this.availableDateForFirstVaccination} onChange={this.dateOfFirstVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.firstappointmentlocation || this.state.secondappointmentonly === "Yes" || this.state.prescribertimestamp} />{(this.state.vaccinetype && this.state.firstappointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetype)}</span> : null}<div className="questionnaire-error"><strong>IMPORTANT: Please review the follow up appointment date to ensure it is still appropriate!</strong></div></Label> : null}
                    </FormGroup>
                </Question></Col>
                <Col><Question questionNumber="1.3d" questionText={"Please select a time slot for your first appointment:"} isMandatory={this.isMandatory('firstappointmenttimeslot') && (this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.firstappointmenttimeslot}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("firstappointmenttimeslot") ? <Label>{this.renderAnswer(submission.questionAnswers, "firstappointmenttimeslot")}</Label> : null}
                        {!this.isEditing("firstappointmenttimeslot") ? <Button color="link" onClick={() => this.editField("firstappointmenttimeslot", () => this.getAvailableTimeSlotsForFirstAppointment())}>change</Button> : null}
                        {this.isEditing("firstappointmenttimeslot") ? <Label check><Input type="select" name="firstappointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "firstappointmenttimeslot", true)} value={this.state.firstappointmenttimeslot} disabled={!this.state.firstappointmentdate || this.state.secondappointmentonly === "Yes" || this.state.prescribertimestamp} >
                            <option value="">Please select</option>
                            {this.state.firstAppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                        </Input><Button color="link" onClick={() => this.getAvailableTimeSlotsForFirstAppointment()}>refresh available slots</Button></Label> : null}
                        </FormGroup>
                    </Question></Col>
                </Row>
                <div><strong><h4>2nd Dose Appointment</h4></strong></div>
                <Row>
                    <Col><Question questionNumber="1.3e.i" questionText={"Please select a site for your SECOND vaccination appointment."} isMandatory={this.isMandatory('secondappointmentsite') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.secondappointmentsite}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("secondappointmentsite") ? <Label>{this.renderAnswer(submission.questionAnswers, "secondappointmentsite")}</Label> : null}
                        {!this.isEditing("secondappointmentsite") && this.state.firstappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("secondappointmentsite", () => this.getSchedulesForFollowUpAppointment(() => { this.getAvailableSitesForSecondAppointment(() => { this.editField("secondappointmentlocation", () => this.getAvailableLocationsForSecondAppointment(() => { this.editField("secondappointmentdate", () => this.editField("secondappointmenttimeslot", () => this.getSchedulesForFollowUpAppointment(() => this.getAvailableTimeSlotsForSecondAppointment(() => this.syncFollowUpAppointmentSiteLocation())))) })); }) }))}>change</Button> : null}
                        {this.isEditing("secondappointmentsite") ? <Label check><Input type="select" name="secondappointmentsite" onChange={(e) => this.textChangeHandler(e, "secondappointmentsite", true)} value={this.state.secondappointmentsite} disabled={this.state.firstappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestamp2} >
                            <option value="">Please select</option>
                            {this.state.secondAppointmentSiteList && this.state.secondAppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question></Col>
                <Col><Question questionNumber="1.3e.ii" questionText={"Please select a location for your SECOND vaccination appointment."} isMandatory={this.isMandatory('secondappointmentlocation') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.secondappointmentlocation}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("secondappointmentlocation") ? <Label>{this.renderAnswer(submission.questionAnswers, "secondappointmentlocation")}</Label> : null}
                        {!this.isEditing("secondappointmentlocation") && this.state.firstappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("secondappointmentlocation", () => this.getAvailableLocationsForSecondAppointment(() => { this.editField("secondappointmentdate", () => this.editField("secondappointmenttimeslot", () => this.getSchedulesForFollowUpAppointment(() => this.getAvailableTimeSlotsForSecondAppointment(() => this.syncFollowUpAppointmentSiteLocation())))) }))}>change</Button> : null}
                        {this.isEditing("secondappointmentlocation") ? <Label check><Input type="select" name="secondappointmentlocation" onChange={(e) => this.textChangeHandler(e, "secondappointmentlocation", true)} value={this.state.secondappointmentlocation} disabled={!this.state.secondappointmentsite || this.state.firstappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestamp2} >
                            <option value="">Please select</option>
                            {this.state.secondAppointmentLocationList && this.state.secondAppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input></Label> : null}
                    </FormGroup>
                </Question></Col>
                <Col><Question questionNumber="1.3f" questionText={"Please select an available SECOND appointment date."} isMandatory={this.isMandatory('secondappointmentdate') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.secondappointmentdate}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("secondappointmentdate") ? <Label>{this.renderAnswer(submission.questionAnswers, "secondappointmentdate")}</Label> : null}
                        {!this.isEditing("secondappointmentdate") && this.state.firstappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("secondappointmentdate", () => this.editField("secondappointmenttimeslot", () => this.getSchedulesForFollowUpAppointment(() => this.getAvailableTimeSlotsForSecondAppointment())))}>change</Button> : null}
                        {this.isEditing("secondappointmentdate") && this.state.allowDateOverride ? <div className="date-override-container"><Label><Input type="checkbox" name="secondAppointmentDateOverride" value="Yes" checked={this.state.secondAppointmentDateOverride === "Yes"} onChange={(e) => this.checkedChangeNoStoreHandler(e, "secondappointmentdateoverride")} />Ignore date range restrictions (appointment slot capacity still applies)</Label></div> : null}
                        {this.isEditing("secondappointmentdate") ? <Label check><DatePicker className="form-control date-selection-inputbox" selected={this.state.secondappointmentdate} openToDate={this.state.defaultSecondVaccinationDate} filterDate={this.availableDateForSecondVaccination} onChange={this.dateOfSecondVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.secondappointmentlocation || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || this.state.prescribertimestamp2} /></Label> : null}
                        {(this.state.vaccinetype2 && this.state.secondappointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetype2)}</span> : null}
                        {(this.state.vaccinetype && this.state.vaccinetype2 && this.state.vaccinetype !== this.state.vaccinetype2) && <div className="questionnaire-error"><strong>Vaccine type at the first ({this.getVaccineFullName(this.state.vaccinetype)}) and follow-up ({this.getVaccineFullName(this.state.vaccinetype2)}) appointment locations for selected date DO NOT match. Please check and confirm if OK to continue!</strong></div>}
                    </FormGroup>
                </Question></Col>
                <Col><Question questionNumber="1.3g" questionText={"Please select a time slot for your SECOND appointment:"} isMandatory={this.isMandatory('secondappointmenttimeslot') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.secondappointmenttimeslot}>
                    <FormGroup className="questionnaire-answers">
                        {!this.isEditing("secondappointmenttimeslot") ? <Label>{this.renderAnswer(submission.questionAnswers, "secondappointmenttimeslot")}</Label> : null}
                        {!this.isEditing("secondappointmenttimeslot") && this.state.firstappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("secondappointmenttimeslot", () => this.getAvailableTimeSlotsForSecondAppointment())}>change</Button> : null}
                        {this.isEditing("secondappointmenttimeslot") ? <Label check><Input type="select" name="secondappointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "secondappointmenttimeslot", true)} value={this.state.secondappointmenttimeslot} disabled={!this.state.secondappointmentdate || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || this.state.prescribertimestamp2} >
                            <option value="">Please select</option>
                            {this.state.secondAppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                        </Input><Button color="link" onClick={() => this.getAvailableTimeSlotsForSecondAppointment()}>refresh available slots</Button></Label> : null}
                        </FormGroup>
                    </Question></Col>
                </Row>
                <div><strong><h4>3rd Dose Appointment</h4></strong></div>
                <Row>
                    <Col>
                        <Question questionNumber="1.3h.i" questionText={"Please select a site for your THIRD vaccination appointment."} isMandatory={this.isMandatory('thirdappointmentsite') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.thirdappointmentsite}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("thirdappointmentsite") ? <Label>{this.renderAnswer(submission.questionAnswers, "thirdappointmentsite")}</Label> : null}
                                {!this.isEditing("thirdappointmentsite") && (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes") ? <Button color="link" onClick={() => this.editField("thirdappointmentsite", () => this.getSchedulesForThirdAppointment(() => { this.getAvailableSitesForThirdAppointment(() => { this.editField("thirdappointmentlocation", () => this.getAvailableLocationsForThirdAppointment(() => { this.editField("thirdappointmentdate", () => this.editField("thirdappointmenttimeslot", () => this.getAvailableTimeSlotsForThirdAppointment())) })); }) }))}>change</Button> : null}
                                {this.isEditing("thirdappointmentsite") ? <Label check><Input type="select" name="thirdappointmentsite" onChange={(e) => this.textChangeHandler(e, "thirdappointmentsite", true)} value={this.state.thirdappointmentsite} disabled={this.state.firstappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestamp3} >
                                    <option value="">Please select</option>
                                    {this.state.thirdAppointmentSiteList && this.state.thirdAppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3h.ii" questionText={"Please select a location for your THIRD vaccination appointment."} isMandatory={this.isMandatory('thirdappointmentlocation') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.thirdappointmentlocation}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("thirdappointmentlocation") ? <Label>{this.renderAnswer(submission.questionAnswers, "thirdappointmentlocation")}</Label> : null}
                                {!this.isEditing("thirdappointmentlocation") && this.state.firstappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("thirdappointmentlocation", () => this.getAvailableLocationsForThirdAppointment(() => { this.editField("thirdappointmentdate", () => this.editField("thirdappointmenttimeslot", () => this.getSchedulesForThirdAppointment(() => this.getAvailableTimeSlotsForThirdAppointment()))) }))}>change</Button> : null}
                                {this.isEditing("thirdappointmentlocation") ? <Label check><Input type="select" name="thirdappointmentlocation" onChange={(e) => this.textChangeHandler(e, "thirdappointmentlocation", true)} value={this.state.thirdappointmentlocation} disabled={!this.state.thirdappointmentsite || this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestamp3} >
                                    <option value="">Please select</option>
                                    {this.state.thirdAppointmentLocationList && this.state.thirdAppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3i" questionText={"Please select an available THIRD appointment date."} isMandatory={this.isMandatory('thirdappointmentdate')} unanswered={this.state.initialised && !this.state.thirdappointmentdate}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("thirdappointmentdate") ? <Label>{this.renderAnswer(submission.questionAnswers, "thirdappointmentdate")}</Label> : null}
                                {!this.isEditing("thirdappointmentdate") && (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes") ? <Button color="link" onClick={() => this.editField("thirdappointmentdate", () => this.editField("thirdappointmenttimeslot", () => this.getSchedulesForThirdAppointment(() => this.getAvailableTimeSlotsForThirdAppointment())))}>change</Button> : null}
                                {this.isEditing("thirdappointmentdate") && this.state.allowDateOverride ? <div className="date-override-container"><Label><Input type="checkbox" name="thirdAppointmentDateOverride" value="Yes" checked={this.state.thirdAppointmentDateOverride === "Yes"} onChange={(e) => this.checkedChangeNoStoreHandler(e, "thirdappointmentdateoverride")} />Ignore date range restrictions (appointment slot capacity still applies)</Label></div> : null}
                                {this.isEditing("thirdappointmentdate") ? <Label check><DatePicker className="form-control date-selection-inputbox" selected={this.state.thirdappointmentdate} openToDate={this.state.defaultThirdVaccinationDate} filterDate={this.availableDateForThirdVaccination} onChange={this.dateOfThirdVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.thirdappointmentlocation || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || (this.state.secondappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.secondappointmenttimeslot)) || this.state.prescribertimestamp3} /></Label> : null}
                                {(this.state.vaccinetype3 && this.state.thirdappointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetype3)}</span> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3j" questionText={"Please select a time slot for your THIRD appointment:"} isMandatory={this.isMandatory('thirdappointmenttimeslot')} unanswered={this.state.initialised && !this.state.thirdappointmenttimeslot}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("thirdappointmenttimeslot") ? <Label>{this.renderAnswer(submission.questionAnswers, "thirdappointmenttimeslot")}</Label> : null}
                                {!this.isEditing("thirdappointmenttimeslot") && this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("thirdappointmenttimeslot", () => this.getAvailableTimeSlotsForThirdAppointment())}>change</Button> : null}
                                {this.isEditing("thirdappointmenttimeslot") ? <Label check><Input type="select" name="thirdappointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "thirdappointmenttimeslot", true)} value={this.state.thirdappointmenttimeslot} disabled={!this.state.thirdappointmentdate || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || (this.state.secondappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.secondappointmenttimeslot)) || this.state.prescribertimestamp3} >
                                    <option value="">Please select</option>
                                    {this.state.thirdAppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                                </Input><Button color="link" onClick={() => this.getAvailableTimeSlotsForThirdAppointment()}>refresh available slots</Button></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                </Row>
                <div><strong><h4>Booster Dose Appointment</h4></strong></div>
                <Row>
                    <Col>
                        <Question questionNumber="1.3h.i" questionText={"Please select a site for your BOOSTER vaccination appointment."} isMandatory={this.isMandatory('boost1appointmentsite') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.boost1appointmentsite}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("boost1appointmentsite") ? <Label>{this.renderAnswer(submission.questionAnswers, "boost1appointmentsite")}</Label> : null}
                                {!this.isEditing("boost1appointmentsite") && (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes") ? <Button color="link" onClick={() => this.editField("boost1appointmentsite", () => this.getSchedulesForBoosterAppointment(() => { this.getAvailableSitesForBoosterAppointment(() => { this.editField("boost1appointmentlocation", () => this.getAvailableLocationsForBoosterAppointment(() => { this.editField("boost1appointmentdate", () => this.editField("boost1appointmenttimeslot", () => this.getAvailableTimeSlotsForBoosterAppointment())) })); }) }))}>change</Button> : null}
                                {this.isEditing("boost1appointmentsite") ? <Label check><Input type="select" name="boost1appointmentsite" onChange={(e) => this.textChangeHandler(e, "boost1appointmentsite", true)} value={this.state.boost1appointmentsite} disabled={this.state.firstappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestampboost1} >
                                    <option value="">Please select</option>
                                    {this.state.boost1AppointmentSiteList && this.state.boost1AppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3h.ii" questionText={"Please select a location for your BOOSTER vaccination appointment."} isMandatory={this.isMandatory('boost1appointmentlocation') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.boost1appointmentlocation}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("boost1appointmentlocation") ? <Label>{this.renderAnswer(submission.questionAnswers, "boost1appointmentlocation")}</Label> : null}
                                {!this.isEditing("boost1appointmentlocation") && this.state.firstappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("boost1appointmentlocation", () => this.getAvailableLocationsForBoosterAppointment(() => { this.editField("boost1appointmentdate", () => this.editField("boost1appointmenttimeslot", () => this.getSchedulesForBoosterAppointment(() => this.getAvailableTimeSlotsForBoosterAppointment()))) }))}>change</Button> : null}
                                {this.isEditing("boost1appointmentlocation") ? <Label check><Input type="select" name="boost1appointmentlocation" onChange={(e) => this.textChangeHandler(e, "boost1appointmentlocation", true)} value={this.state.boost1appointmentlocation} disabled={!this.state.boost1appointmentsite || this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestampboost1} >
                                    <option value="">Please select</option>
                                    {this.state.boost1AppointmentLocationList && this.state.boost1AppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3i" questionText={"Please select an available BOOSTER appointment date."} isMandatory={this.isMandatory('boost1appointmentdate')} unanswered={this.state.initialised && !this.state.boost1appointmentdate}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("boost1appointmentdate") ? <Label>{this.renderAnswer(submission.questionAnswers, "boost1appointmentdate")}</Label> : null}
                                {!this.isEditing("boost1appointmentdate") && (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes") ? <Button color="link" onClick={() => this.editField("boost1appointmentdate", () => this.editField("boost1appointmenttimeslot", () => this.getSchedulesForBoosterAppointment(() => this.getAvailableTimeSlotsForBoosterAppointment())))}>change</Button> : null}
                                {this.isEditing("boost1appointmentdate") && this.state.allowDateOverride ? <div className="date-override-container"><Label><Input type="checkbox" name="boost1AppointmentDateOverride" value="Yes" checked={this.state.boost1AppointmentDateOverride === "Yes"} onChange={(e) => this.checkedChangeNoStoreHandler(e, "boost1appointmentdateoverride")} />Ignore date range restrictions (appointment slot capacity still applies)</Label></div> : null}
                                {this.isEditing("boost1appointmentdate") ? <Label check><DatePicker className="form-control date-selection-inputbox" selected={this.state.boost1appointmentdate} openToDate={this.state.defaultBoosterVaccinationDate} filterDate={this.availableDateForBoosterVaccination} onChange={this.dateOfBoosterVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.boost1appointmentlocation || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || (this.state.secondappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.secondappointmenttimeslot)) || this.state.prescribertimestampboost1} /></Label> : null}
                                {(this.state.vaccinetypeboost1 && this.state.boost1appointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetypeboost1)}</span> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3j" questionText={"Please select a time slot for your BOOSTER appointment:"} isMandatory={this.isMandatory('boost1appointmenttimeslot')} unanswered={this.state.initialised && !this.state.boost1appointmenttimeslot}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("boost1appointmenttimeslot") ? <Label>{this.renderAnswer(submission.questionAnswers, "boost1appointmenttimeslot")}</Label> : null}
                                {!this.isEditing("boost1appointmenttimeslot") && this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("boost1appointmenttimeslot", () => this.getAvailableTimeSlotsForBoosterAppointment())}>change</Button> : null}
                                {this.isEditing("boost1appointmenttimeslot") ? <Label check><Input type="select" name="boost1appointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "boost1appointmenttimeslot", true)} value={this.state.boost1appointmenttimeslot} disabled={!this.state.boost1appointmentdate || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || (this.state.secondappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.secondappointmenttimeslot)) || this.state.prescribertimestampboost1} >
                                    <option value="">Please select</option>
                                    {this.state.boost1AppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                                </Input><Button color="link" onClick={() => this.getAvailableTimeSlotsForBoosterAppointment()}>refresh available slots</Button></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                </Row>
                <div><strong><h4>Flu Dose Appointment</h4></strong></div>
                <Row>
                    <Col>
                        <Question questionNumber="1.3h.i" questionText={"Please select a site for your FLU vaccination appointment."} isMandatory={this.isMandatory('flu1appointmentsite') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.flu1appointmentsite}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("flu1appointmentsite") ? <Label>{this.renderAnswer(submission.questionAnswers, "flu1appointmentsite")}</Label> : null}
                                {!this.isEditing("flu1appointmentsite") && (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes") ? <Button color="link" onClick={() => this.editField("flu1appointmentsite", () => this.getSchedulesForFluAppointment(() => { this.getAvailableSitesForFluAppointment(() => { this.editField("flu1appointmentlocation", () => this.getAvailableLocationsForFluAppointment(() => { this.editField("flu1appointmentdate", () => this.editField("flu1appointmenttimeslot", () => this.getAvailableTimeSlotsForFluAppointment())) })); }) }))}>change</Button> : null}
                                {this.isEditing("flu1appointmentsite") ? <Label check><Input type="select" name="flu1appointmentsite" onChange={(e) => this.textChangeHandler(e, "flu1appointmentsite", true)} value={this.state.flu1appointmentsite} disabled={this.state.firstappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestampflu1} >
                                    <option value="">Please select</option>
                                    {this.state.flu1AppointmentSiteList && this.state.flu1AppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3h.ii" questionText={"Please select a location for your FLU vaccination appointment."} isMandatory={this.isMandatory('flu1appointmentlocation') && (this.state.firstappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes")} unanswered={this.state.initialised && !this.state.flu1appointmentlocation}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("flu1appointmentlocation") ? <Label>{this.renderAnswer(submission.questionAnswers, "flu1appointmentlocation")}</Label> : null}
                                {!this.isEditing("flu1appointmentlocation") && this.state.firstappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("flu1appointmentlocation", () => this.getAvailableLocationsForFluAppointment(() => { this.editField("flu1appointmentdate", () => this.editField("flu1appointmenttimeslot", () => this.getSchedulesForFluAppointment(() => this.getAvailableTimeSlotsForFluAppointment()))) }))}>change</Button> : null}
                                {this.isEditing("flu1appointmentlocation") ? <Label check><Input type="select" name="flu1appointmentlocation" onChange={(e) => this.textChangeHandler(e, "flu1appointmentlocation", true)} value={this.state.flu1appointmentlocation} disabled={!this.state.flu1appointmentsite || this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes" || !this.state.allowDateOverride || this.state.prescribertimestampflu1} >
                                    <option value="">Please select</option>
                                    {this.state.flu1AppointmentLocationList && this.state.flu1AppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3i" questionText={"Please select an available FLU appointment date."} isMandatory={this.isMandatory('flu1appointmentdate')} unanswered={this.state.initialised && !this.state.flu1appointmentdate}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("flu1appointmentdate") ? <Label>{this.renderAnswer(submission.questionAnswers, "flu1appointmentdate")}</Label> : null}
                                {!this.isEditing("flu1appointmentdate") && (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes") ? <Button color="link" onClick={() => this.editField("flu1appointmentdate", () => this.editField("flu1appointmenttimeslot", () => this.getSchedulesForFluAppointment(() => this.getAvailableTimeSlotsForFluAppointment())))}>change</Button> : null}
                                {this.isEditing("flu1appointmentdate") && this.state.allowDateOverride ? <div className="date-override-container"><Label><Input type="checkbox" name="flu1AppointmentDateOverride" value="Yes" checked={this.state.flu1AppointmentDateOverride === "Yes"} onChange={(e) => this.checkedChangeNoStoreHandler(e, "flu1appointmentdateoverride")} />Ignore date range restrictions (appointment slot capacity still applies)</Label></div> : null}
                                {this.isEditing("flu1appointmentdate") ? <Label check><DatePicker className="form-control date-selection-inputbox" selected={this.state.flu1appointmentdate} openToDate={this.state.defaultFluVaccinationDate} filterDate={this.availableDateForFluVaccination} onChange={this.dateOfFluVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.flu1appointmentlocation || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || (this.state.secondappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.secondappointmenttimeslot)) || this.state.prescribertimestampflu1} /></Label> : null}
                                {(this.state.vaccinetypeflu1 && this.state.flu1appointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetypeflu1)}</span> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                    <Col>
                        <Question questionNumber="1.3j" questionText={"Please select a time slot for your FLU appointment:"} isMandatory={this.isMandatory('thirdappointmenttimeslot')} unanswered={this.state.initialised && !this.state.flu1appointmenttimeslot}>
                            <FormGroup className="questionnaire-answers">
                                {!this.isEditing("flu1appointmenttimeslot") ? <Label>{this.renderAnswer(submission.questionAnswers, "flu1appointmenttimeslot")}</Label> : null}
                                {!this.isEditing("flu1appointmenttimeslot") && this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" ? <Button color="link" onClick={() => this.editField("flu1appointmenttimeslot", () => this.getAvailableTimeSlotsForFluAppointment())}>change</Button> : null}
                                {this.isEditing("flu1appointmenttimeslot") ? <Label check><Input type="select" name="flu1appointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "flu1appointmenttimeslot", true)} value={this.state.flu1appointmenttimeslot} disabled={!this.state.flu1appointmentdate || (this.state.firstappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.firstappointmenttimeslot)) || (this.state.secondappointmentonly === "Yes" || (this.state.firstappointmentonly !== "Yes" && this.state.secondappointmentonly !== "Yes" && this.state.thirdappointmentonly !== "Yes" && !this.state.secondappointmenttimeslot)) || this.state.prescribertimestampflu1} >
                                    <option value="">Please select</option>
                                    {this.state.flu1AppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                                </Input><Button color="link" onClick={() => this.getAvailableTimeSlotsForFluAppointment()}>refresh available slots</Button></Label> : null}
                            </FormGroup>
                        </Question>
                    </Col>
                </Row>

                <div>
                    <h3>Data Consent and Other Confirmations</h3>
                    <Question questionNumber="3.1" questionText={"Patient or HCW informed that if they have a question about how and why their information is collected and shared, to refer to our website www.guysandstthomas.nhs.uk  and search for \"your health records\""}>
                        <FormGroup className="questionnaire-answers">
                            <Label>{this.renderAnswer(submission.questionAnswers, "consentshare")}</Label>
                        </FormGroup>
                    </Question>
                </div>
                <div>
                    <h3>PSDs</h3>
                    <Button color="primary" onClick={() => this.toggleShowFirstPsd()} disabled={!this.state.prescribertimestamp}>{this.state.showingFirstPsd ? "Hide" : "View"} 1st PSD</Button>
                    {this.state.showingFirstPsd && this.renderPsd("first", true)}
                    &nbsp;<Button color="primary" onClick={() => this.toggleShowSecondPsd()} disabled={!this.state.prescribertimestamp2}>{this.state.showingSecondPsd ? "Hide" : "View"} 2nd PSD</Button>
                    {this.state.showingSecondPsd && this.renderPsd("second", true)}
                    &nbsp;<Button color="primary" onClick={() => this.toggleShowThirdPsd()} disabled={!this.state.prescribertimestamp3}>{this.state.showingThirdPsd ? "Hide" : "View"} 3rd PSD</Button>
                    {this.state.showingThirdPsd && this.renderPsd("third", true)}
                    &nbsp;<Button color="primary" onClick={() => this.toggleShowBoosterPsd()} disabled={!this.state.prescribertimestampboost1}>{this.state.showingBoosterPsd ? "Hide" : "View"} Booster PSD</Button>
                    {this.state.showingBoosterPsd && this.renderPsd("boost1", true)}
                    &nbsp;<Button color="primary" onClick={() => this.toggleShowFluPsd()} disabled={!this.state.prescribertimestampflu1}>{this.state.showingFluPsd ? "Hide" : "View"} Flu PSD</Button>
                    {this.state.showingFluPsd && this.renderPsd("flu1", true)}
                </div>
                <div>
                    <h2>Administrative Actions</h2>
                    <div><h4>1st Appointment</h4></div>
                    <Row>
                        <Col>
                            <Question questionNumber="2.4a" questionText={"First Appointment Cancellation/DNA?"} isMandatory={this.state.firstappointmentdate}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="select" name="outcomeforfirstapp" onChange={(e) => this.textChangeHandler(e, "outcomeforfirstapp", true)} value={this.state.outcomeforfirstapp} disabled={!this.state.firstappointmentdate || this.state.prescribertimestamp}>
                                            <option value="">Please select</option>
                                            {this.state.firstAppointmentOutcomeReasonsList.map((di, idx) => <option key={idx} value={di.id}>{di.name}</option>)}
                                        </Input></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                        <Col>
                            <Question questionNumber="2.4b" questionText={"First Appointment Comments"}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="textarea" name="commentsforfirstapp" value={this.state.commentsforfirstapp} onChange={(e) => this.textChangeHandler(e, "commentsforfirstapp", true)} maxLength={500} cols={80} rows={2} disabled={this.state.secondappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes"} /></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                    </Row>
                    <div><h4>2nd Dose Appointment</h4></div>
                    <Row>
                        <Col>
                            <Question questionNumber="2.5a" questionText={"Second Appointment Cancellation/DNA?"} isMandatory={this.secondAppointmentAdminQuestionsAvailable()}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="select" name="outcomeforsecondapp" onChange={(e) => this.textChangeHandler(e, "outcomeforsecondapp", true)} value={this.state.outcomeforsecondapp} disabled={!this.state.secondappointmentdate || this.state.prescribertimestamp2}>
                                            <option value="">Please select</option>
                                            {this.state.secondAppointmentOutcomeReasonsList.map((di, idx) => <option key={idx} value={di.id}>{di.name}</option>)}
                                        </Input></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                        <Col>
                            <Question questionNumber="2.5b" questionText={"Second Appointment Comments"}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="textarea" name="commentsforsecondapp" value={this.state.commentsforsecondapp} onChange={(e) => this.textChangeHandler(e, "commentsforsecondapp", true)} maxLength={500} cols={80} rows={2} disabled={this.state.firstappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes"} /></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                    </Row>
                    <div><h4>3rd Dose Appointment</h4></div>
                    <Row>
                        <Col>
                            <Question questionNumber="2.6a" questionText={"Third Appointment Cancellation/DNA?"} isMandatory={this.thirdAppointmentAdminQuestionsAvailable()}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="select" name="outcomeforthirdapp" onChange={(e) => this.textChangeHandler(e, "outcomeforthirdapp", true)} value={this.state.outcomeforthirdapp} disabled={!this.state.thirdappointmentdate || this.state.prescribertimestamp3}>
                                            <option value="">Please select</option>
                                            {this.state.thirdAppointmentOutcomeReasonsList.map((di, idx) => <option key={idx} value={di.id}>{di.name}</option>)}
                                        </Input></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                        <Col>
                            <Question questionNumber="2.6b" questionText={"Third Appointment Comments"}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="textarea" name="commentsforthirdapp" value={this.state.commentsforthirdapp} onChange={(e) => this.textChangeHandler(e, "commentsforthirdapp", true)} maxLength={500} cols={80} rows={2} disabled={(this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes") && !this.state.prescriberadaccount3} /></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                    </Row>
                    <div><h4>Booster Dose Appointment</h4></div>
                    <Row>
                        <Col>
                            <Question questionNumber="2.7a" questionText={"Booster Appointment Cancellation/DNA?"} isMandatory={this.boost1AppointmentAdminQuestionsAvailable()}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="select" name="outcomeforboost1app" onChange={(e) => this.textChangeHandler(e, "outcomeforboost1app", true)} value={this.state.outcomeforboost1app} disabled={!this.state.boost1appointmentdate || this.state.prescribertimestampboost1}>
                                            <option value="">Please select</option>
                                            {this.state.boost1AppointmentOutcomeReasonsList.map((di, idx) => <option key={idx} value={di.id}>{di.name}</option>)}
                                        </Input></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                        <Col>
                            <Question questionNumber="2.7b" questionText={"Booster Appointment Comments"}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="textarea" name="commentsforboost1app" value={this.state.commentsforboost1app} onChange={(e) => this.textChangeHandler(e, "commentsforboost1app", true)} maxLength={500} cols={80} rows={2} disabled={(this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes") && !this.state.prescriberadaccountboost1} /></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                    </Row>
                    <div><h4>Flu Dose Appointment</h4></div>
                    <Row>
                        <Col>
                            <Question questionNumber="2.8a" questionText={"Flu Appointment Cancellation/DNA?"} isMandatory={this.flu1AppointmentAdminQuestionsAvailable()}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="select" name="outcomeforflu1app" onChange={(e) => this.textChangeHandler(e, "outcomeforflu1app", true)} value={this.state.outcomeforflu1app} disabled={!this.state.flu1appointmentdate || this.state.prescribertimestampflu1}>
                                            <option value="">Please select</option>
                                            {this.state.flu1AppointmentOutcomeReasonsList.map((di, idx) => <option key={idx} value={di.id}>{di.name}</option>)}
                                        </Input></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                        <Col>
                            <Question questionNumber="2.8b" questionText={"Flu Appointment Comments"}>
                                <FormGroup className="questionnaire-answers">
                                    <Label check>
                                        <Label check><Input type="textarea" name="commentsforflu1app" value={this.state.commentsforflu1app} onChange={(e) => this.textChangeHandler(e, "commentsforflu1app", true)} maxLength={500} cols={80} rows={2} disabled={(this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes") && !this.state.prescriberadaccountflu1} /></Label>
                                    </Label>
                                </FormGroup>
                            </Question>
                        </Col>
                    </Row>
                    {!this.state.showDeleteConfirm && !this.props.saved && <div>{this.state.isInvalid ? <Button color="warning" onClick={() => this.updateAdminFields(true)} disabled={this.state.submitting}>Save Data (ignore warnings)</Button> : <Button color="success" onClick={() => this.updateAdminFields()} disabled={this.state.submitting}>Save Data</Button>}&nbsp;{!this.state.isInvalid && this.state.allowDateOverride ? <Button color="warning" disabled={this.state.submitting} onClick={() => this.showDeleteSubmission()}>Remove Registration...</Button> : null}<br />&nbsp;</div>}
                    {this.state.showDeleteConfirm && <div className="questionnaire-error">Removing a registration is irreversible and should only be performed to remove duplicate registrations. If a vaccination appointment was DNA'd or had to be cancelled then please select this in the appropriate appointment's dropdown list and Save Data as normal.<br /><Button color="danger" disabled={this.state.submitting} onClick={() => this.deleteSubmission()}>Confirm Remove Registration</Button>&nbsp;<Button color="secondary" onClick={() => this.hideDeleteSubmission()}>Cancel</Button><br />&nbsp;</div>}
                </div>
                {this.state.errored && <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
                {this.state.isInvalid && <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review the answers and re-submit.</strong>{this.state.validationErrors.length > 0 ? <ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul> : null}</div>}
                <br />
                {this.props.saved && returnCallback && <div><Button color="secondary" onClick={() => returnCallback()} disabled={this.state.submitting}>Finished</Button></div>}
                {!this.props.saved && returnCallback && <div><Button color="secondary" onClick={() => returnCallback()} disabled={this.state.submitting}>Return to list</Button></div>}
            </div>
        </div>;
    }

};

export default ViewQuestionnaireSubmission;